import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import AssemblyMonitor from "./assemblyMonitor";

class Routes extends Component  {
  render() {
    return(
      <Switch>
        <Route
          exact
          path="/monitors/assemblyMonitor" 
          component={AssemblyMonitor}
        />
      </Switch> 
    )
  }

}

export default Routes;