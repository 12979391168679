import React from "react";
import styled from 'styled-components';
import { Table, Button } from 'reactstrap';
import EditUser from './editUser';

class Users extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      showEditUser: false,
      editUser: {}
    }
  }

  replaceRole = (role) => {
    if (role === 1) return 'Super';
    else if (role === 2) return 'Admin';
		else if (role === 5) return 'User';
		else return 'Unknown'
  }
  

  toggle = () => {
    this.setState(prevState => ({showEditUser: !prevState.showEditUser, editUser: {}}));
  }

  editUser = (user) => {
    this.setState({showEditUser: true, editUser: user});
  }

	render() {
    const { users } = this.props 
    const { showEditUser, editUser } = this.state;
		return (
      <CustomTable striped>
        <EditUser toggle={this.toggle} show={showEditUser} user={editUser} updateUser={this.props.updateUser}/>
        <thead>
          <tr>
            <th>User</th>
            <th>Role</th>
            <th>Department</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, i) => (
          <tr key={user._id} className={!user.isActive ? 'disabled' : null}>
            <td className="cell-custom-title">
              {user.firstName} {user.lastName} <br/>
              {user.email}
            </td>
            <td>{this.replaceRole(user.role)}</td>
            <td>{user.department}</td>
            <td><Button variant="contained" color="primary" onClick={() => this.editUser(user)}>Edit User</Button></td>
          </tr>
          ))}
        </tbody>
      </CustomTable>
		)
	}
}

const CustomTable = styled(Table)`
  th, td {
    vertical-align: middle;
  }

  .disabled {
    background: #dd3333 !important;
    color: white !important;

    .cell-custom, .cell-custom-title {
      color: white;

      .name {
        color: white;
      }
    }
    
  }
`;

export default Users