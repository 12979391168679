import React from 'react';
import Styled from 'styled-components';
import { AlertTriangle } from 'react-feather';

const SickDiv = Styled.div`
	width: 100%;
	height: 100vh;
	background-color: #e0e0e0;
	display: flex;
	justify-content: center;
	align-items: center;

	h1 {
		font-weight: lighter;
		text-align: center;
		padding: 10px;
		font-size: 1.5em;
	}
`;

const NotFound = () => (
	<SickDiv>
		<h1><AlertTriangle size={20} /> 404 PAGE - WHY YOU DO DIS</h1>
	</SickDiv>
)

export default NotFound;