import React, { Component } from 'react';
import styled from 'styled-components';
import { chain, zipObject, sortBy } from 'lodash';
import moment from 'moment';
import randomColor from "randomcolor";
import { Table, Button } from 'reactstrap';

import { getLoadedItems } from '../../../actions/itemActions';

import { PackageIcon } from 'styled-icons/boxicons-solid';

import Title from '../components/reports/title.component';
import TimeStamp from '../components/reports/timestamp.component';
import DateSelect from '../components/reports/dateSelect.component';


class ShippingReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedDate: props.match.params.date ? new Date(`${props.match.params.date} CST`) : new Date(),
      items: []
    }
  }

  componentDidMount() {
    this.getItems(this.state.selectedDate);
  }

  getItems = (date) => {
    getLoadedItems(moment(date).format('YYYY-MM-DD')).then((response) => {

      var items = chain(response.data)
        .groupBy("orderNumber")
        .toPairs()
        .map((currentItem) => {
          return zipObject(["orderNumber", "items"], currentItem);
        })
        .value();

      items = sortBy(items, function (el) {
        return el.items[0].order.serviceArea;
      });

      this.setState({ items: items})
    }).catch((err) => {
      this.setState({ items: [] })
    });
  }

  handleDateChange = (date) => {
    this.setState({
      selectedDate: date,
      updated: new Date()
    })
    this.getItems(date);
  }

  randomColor = (status) => {
    if (status === "Released Work Order") return;
    var color = randomColor({
        luminosity: 'dark',
        seed: status
    });

    return {
      background: color,
      color: 'white'
    }
  }

  render() {
    const { selectedDate, items, updated } = this.state;

    return (
      <div className="container">
        <Title title="Shipping Report"><PackageIcon size={40} /></Title>
        <TimeStamp date={updated} />
        <DateSelect selectedDate={selectedDate} onChange={this.handleDateChange} />
        <Button onClick={() => this.getItems(selectedDate)} color="primary" >Refresh</Button>
        <hr />
        { items.length > 1 ? items.map((order, i) => (
          <React.Fragment key={order.orderNumber}>
            
            <Info>
              <h5>Order #{order.orderNumber}</h5>
              <h6><strong>Area: </strong>{order.items[0].order.serviceArea}</h6>
              <h6><strong>Tech: </strong>{order.items[0].order.serviceTech}</h6>
              <h6><strong>Super: </strong>{order.items[0].order.super} - {order.items[0].order.superPhone}</h6>
              <h6><strong>Tech: </strong>{order.items[0].order.serviceTech}</h6>
              <h6><strong>Address: </strong></h6>
              <h6>{order.items[0].order.address.streetAddress}</h6>
              <h6>{order.items[0].order.address.subdivision}</h6>
              <h6>{order.items[0].order.address.city}, {order.items[0].order.address.state}</h6>
              <h6>{order.items[0].order.address.zip}</h6>
            </Info>

            <CustomTable striped>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {order.items.map((item, i) => (
                <tr key={item._id}>
                  <td>{item.itemDescription}</td>
                  <td style={this.randomColor(item.currentStatus)}>{item.currentStatus}</td>
                </tr>
                ))}
              </tbody>
            </CustomTable>            
          </React.Fragment>
        )) : 'No Orders Found'}
      </div>
    )
  }
}

const Info = styled.div`
  margin-top: 50px;
  h5 {
    font-weight: bolder;
  }
`;

const CustomTable = styled(Table)`

  th, td {
    vertical-align: middle;
  }
`;

export default ShippingReport;
