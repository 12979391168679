import React from 'react';
import Styled from 'styled-components';

const Title = ({title, children}) => (
    <TitleContainer>
        {children}
        <h1>{title}</h1>
    </TitleContainer>
)

export default Title;

const TitleContainer = Styled.div`
    margin-top: 20px;
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #484848;
    h1 {
        margin-left: 15px;
        font-weight: bolder;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 1.25em;
    }
`;