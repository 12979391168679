import React from 'react';
import Styled from 'styled-components';
import { getLocations } from '../../../actions/monitorActions';

import Header from './components/header';
import Counter from './components/counters';

class AssemblyMonitor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCounts: {},
            loading: true,
            error: false,
            lastUpdate: null
        }
    }

    componentDidMount() {
        this.getNewData();

        setInterval(() => {
            this.getNewData();
        }, 60000);
    }


    getNewData = () => {
        getLocations().then((response) => {
            this.setState({ currentCounts: response.data, loading: false, error: false, lastUpdate: Date.now() })
        }).catch((err) => {
            this.setState({ loading: false, error: true, lastUpdate: Date.now() })
        })
    }


    render() {
        const { currentCounts, loading, error, lastUpdate } = this.state;
        return (
            <Container>
               <Header title="Assembly Counts" error={error} loading={loading} updated={lastUpdate} />
               <Counter counts={currentCounts} />
            </Container>
        )
    }
}

export default AssemblyMonitor;

const Container = Styled.div`
    margin: 0;
    padding: 0;
`;