const initialState = {
  isAuthenticated: false,
  email: null,
  firstName: null,
  lastName: null,
  token: null
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER':
    return { ...state,
      email: action.email,
      firstName: action.firstName,
      lastName: action.lastName,
      role: action.role,
      token: action.token
    }
    case 'SET_AUTHENTICATED':
    return { ...state,
      isAuthenticated: action.isAuthenticated
    }
    default:
    return state
  }
 }
 
 export default authReducer