import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Button, FormGroup, Form, Input } from 'reactstrap';
import { forgotPassword } from '../../actions/authenticationActions';


const Page = styled.div`
	display: flex;
	position: relative;
  padding: 40px 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-height: 100vh;
  background: url('/login/background.jpg') no-repeat center center fixed;
  background-size: cover;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 50px;
  width: 100%;
  flex-shrink: 0;
  max-width: 500px;
  border-radius: 5px;
  background: #FAFAFA;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
  color: #2d3039;

  .sub-title {
    font-weight: lighter;
    font-size: 2em;
    margin: 20px 0;
    text-align: center;
  }

  .form {
    width: 100%;
  }

  .bottom {
    margin: 15px 0;
    width: 100%;
    text-align: center;
  }
`;

class Forgot extends Component {
 
  state = {
    submitted: false,
    reset: false,
    email: '',
  };

  componentDidMount() {
    if (this.props.isAuthenticated) this.props.history.push("/");
  }

  attemptForgot = (event) => {
    event.preventDefault();
    if (this.state.submitted) return;
    this.setState({submitted: true});
    forgotPassword({email: this.state.email }).then(response => this.reset()).catch(error => this.reset())
  };

  reset = () => {
    this.setState({submitted: false, reset: true});
    setTimeout(() => {
      this.props.history.push("/");
    }, 3000)
  }

  handleChange = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
  }

  render() {
    return (
      <Page>
        {!this.state.reset ? 
          <Container>
            <div className="sub-title">Forgot password</div>
            <Form className="form" onSubmit={this.attemptForgot}>
              <FormGroup>
                <Input 
                  id="email"
                  name="email"
                  type="email"
                  bsSize="lg"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <Button color="primary" size="lg" block>Send Reset Link</Button>
              <div className="bottom" />
            </Form>
          </Container> :
          <Container>
            <div className="sub-title">Please check the provided email for a link to reset your password.</div>
            You will be redirected in 3 seconds.
          </Container>
        }
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ forgotPassword }, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Forgot));