import { axiosInstance } from './axios';

export function getOrders() {
	return axiosInstance.get('orders');
}

export function getOrder(orderNumber) {
	return axiosInstance.get(`orders/${orderNumber}`);
}

export function importOrder(orderNumber) {
	return axiosInstance.post('orders', {orderNumber: orderNumber});
}

export function updateOrder(id, order) {
	return axiosInstance.put(`orders/${id}`, order);
}

export function addNote(id, note) {
	return axiosInstance.put(`orders/${id}`, { orderNote: note });
}