import React from "react";
import styled from 'styled-components';
import {connect} from 'react-redux';
import {notify} from 'reapop';
import { Button, Label, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Switch from 'rc-switch';
import { newLocation } from '../../../actions/storageLocationActions';


class ModalContainer extends React.Component {

  state = {
    name: '',
    description: '',
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    onCampus: false
  }

  initializeState = () => {
    this.setState({
      name: '',
      description: '',
      streetAddress: '',
      city: '',
      state: '',
      zip: '',
      onCampus: false
    });
  };


  handleChange = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
  }

  handleCheckChange = (value, event) => {
    this.setState({ onCampus: value });
  }

  newLocation = () => {
    var location = {
      name: this.state.name,
      description: this.state.description,
      onCampus: this.state.onCampus,
      address: {
        streetAddress: this.state.streetAddress,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip
      }
    }

    newLocation(location).then(response => {
      this.props.notify({ title: 'Created', message: `Location ${response.data.name} sucessfully created.`, status: 'success', position: 'bl' });
      this.props.update(response.data);
      this.props.toggle();
    }).catch(error => this.props.notify({ status: 'error', position: 'bl', title: `${error.response.status}`, message: `${error.response.statusText}` }))
  }

	render() {
    const { toggle, show } = this.props;

		return (
      <CustomModal isOpen={show} toggle={toggle} onOpened={this.initializeState} centered>
        <ModalHeader className="header" toggle={toggle}></ModalHeader>
        <ModalBody className="body">
          <div className="title">New Storage Location</div>
          <FormGroup>
            <Label className="label" for="name">Name</Label>
            <Input 
              id="name"
              name="name"
              bsSize="lg"
              placeholder="Name"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label className="label" for="description">Description</Label>
            <Input 
              id="description"
              name="description"
              bsSize="lg"
              placeholder="Description"
              value={this.state.description}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label className="label" for="onCampus">On Campus</Label><br/>
            <Switch
              id="onCampus"
              onChange={this.handleCheckChange}
              checked={this.state.onCampus}
            />
          </FormGroup>
          <FormGroup>
            <Label className="label" for="streetAddress">Street Address</Label>
            <Input 
              id="streetAddress"
              name="streetAddress"
              bsSize="lg"
              placeholder="Street Address"
              value={this.state.streetAddress}
              onChange={this.handleChange}
              disabled={this.state.onCampus}
            />
          </FormGroup>
          <FormGroup>
            <Label className="label" for="city">City</Label>
            <Input 
              id="city"
              name="city"
              bsSize="lg"
              placeholder="City"
              value={this.state.city}
              onChange={this.handleChange}
              disabled={this.state.onCampus}
            />
          </FormGroup>
          <Row>
            <FormGroup>
              <Label className="label" for="state">State</Label>
              <Input 
                id="state"
                name="state"
                bsSize="lg"
                placeholder="State"
                value={this.state.state}
                onChange={this.handleChange}
                disabled={this.state.onCampus}
              />
            </FormGroup>
            <div className="split" />
            <FormGroup>
              <Label className="label" for="zip">Zip</Label>
              <Input 
                id="zip"
                name="zip"
                bsSize="lg"
                placeholder="Zip"
                value={this.state.zip}
                onChange={this.handleChange}
                disabled={this.state.onCampus}
              />
            </FormGroup>
          </Row>
        </ModalBody>
        <ModalFooter className="flex-foot">
          <Button className="button" color="primary" size="lg" onClick={this.newLocation}>Create Location</Button>
        </ModalFooter>
      </CustomModal>
		)
	}
}

const CustomModal = styled(Modal)`
  color: #707070;

  .header, .flex-foot {
    border-bottom: none;
    border-top: none;
  }
  
  .header {
    padding-bottom: 0;
  }

  .title {
    color: #444444;
    font-size: 1.8em;
  }

  .label {
    color: #444444;
    font-size: 0.8em;
  }

  .body {
    padding: 0 1rem;
  }

  .flex-foot {
    display: flex;
    align-items: center;
    .button {
      flex: 1;
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 0.9em;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
`;

const Row = styled.div`
  display: flex;

  .split {
    width: 10px;
  }
`;

export default connect(null, {notify})(ModalContainer);