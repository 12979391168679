import { axiosInstance } from './axios';

const url = 'newSale';

export function getSales() {
	return axiosInstance.get(url);
}

// export function getOrder(orderNumber) {
// 	return axiosInstance.get(`orders/${orderNumber}`);
// }

export function createSale(sale) {
	return axiosInstance.post(url, sale);
}

export function updateSale(sale) {
	return axiosInstance.put(`${url}/${sale._id}`, sale);
}

export function deleteSale(id) {
	return axiosInstance.delete(`${url}/${id}`);
}

// export function updateOrder(id, order) {
// 	return axiosInstance.put(`orders/${id}`, order);
// }

// export function addNote(id, note) {
// 	return axiosInstance.put(`orders/${id}`, { orderNote: note });
// }