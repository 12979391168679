import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, TextField } from '@material-ui/core';
import { resetPassword } from '../../actions/authenticationActions';

const Page = styled.div`
	display: flex;
	position: relative;
  padding: 40px 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-height: 100vh;
  background: url('/login/background.jpg') no-repeat center center fixed;
  background-size: cover;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 50px;
  width: 100%;
  flex-shrink: 0;
  max-width: 500px;
  border-radius: 5px;
  background: #FAFAFA;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
  color: #2d3039;

  .title {
    font-size: 1.2em;
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
  }

  .sub-title {
    font-weight: lighter;
    font-size: 2em;
    margin: 20px 0;
    text-align: center;
  }

  .bottom {
    margin: 15px 0;
    width: 100%;
    text-align: center;

    .sub-text {
      font-weight: lighter;
      margin-top: 30px;
    }

    .link {
      color: #2196f3;
    }
  }
`;

const CustomButton = styled(Button)`
  height: 56px;
  font-weight: bold !important;
`;

class Reset extends Component {

  state = {
    submitted: false,
    reset: false,
    password: '',
    passwordConfirm: '',
    passwordConfirmError: false,
    passwordConfirmErrorMessage: null,
    token: this.props.match.params.token
  };

  attemptReset = () => {
    if (this.state.submitted) return;
    this.setState({submitted: true});
    this.props.actions.resetPassword({password: this.state.password, token: this.state.token }).then(response => this.reset()).catch(error => console.log(error.response))
  };
  
  reset = () => {
    this.setState({submitted: false, reset: true});
    setTimeout(() => {
      this.props.history.push("/");
    }, 3000)
  }


  handleChange = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
  }

  render() {
    return (
      <Page>
        <Container>
          <div className="sub-title">Reset password</div>
          <TextField
              id="reset-password"
              label="Password"
              name="password"
              type="password"
              margin="normal"
              variant="outlined"
              value={this.state.password}
              onChange={this.handleChange}
              fullWidth={true}
            />
            <TextField
              id="reset-password-confirm"
              label="Confirm Password"
              name="passwordConfirm"
              type="password"
              margin="normal"
              variant="outlined"
              value={this.state.passwordConfirm}
              onChange={this.handleChange}
              error={this.state.passwordConfirmError}
              helperText={this.state.passwordConfirmErrorMessage}
              fullWidth={true}
            />
            <div className="bottom">
              <CustomButton variant="contained" fullWidth={true} color="primary" size="large" onClick={this.attemptReset}>Reset Password</CustomButton>
            </div>
        </Container>
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ resetPassword }, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reset));