import { axiosInstance } from './axios';

const url = 'samplematch';

export function getSampleMatches(id) {
	return axiosInstance.get(`${url}/${id}`);
}

export function getAreas(id) {
	return axiosInstance.get(`${url}/areas/${id}`);
}