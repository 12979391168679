import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { signup } from '../../actions/authenticationActions';
import { Button, FormGroup, FormFeedback, Form, Input } from 'reactstrap';

class Signup extends Component {

  state = {
    submitted: false,
    error: null,
    firstNameError: false,
    firstNameErrorMessage: null,
    lastNameError: false,
    lastNameErrorMessage: null,
    emailError: false,
    emailErrorMessage: null,
    passwordError: false,
    passwordErrorMessage: null,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirm: ''
  };

  validatePassword = () => {
    const { password, passwordConfirm } = this.state;

    if (password === '') return 'Password fields must not be blanks';
    if (password !== passwordConfirm) return 'Password fields must match';
    if (password.length < 6 ) return 'Your password must be at least 6 characters';
    if (!/[A-Z]+/.test(password)) return 'Your password must have at least 1 capitol letter'

    return 'success';
  }

  handleChange = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
  }

  attemptSignup = (event) => {
    const { firstName, lastName, email, password } = this.state;
    event.preventDefault();
    if (this.state.submitted) return;
    this.setState({ submitted: true, error: '' });
    var validatePassword = this.validatePassword();
    if (validatePassword !== 'success') return this.setState({submitted: false, error: validatePassword});
    
    this.props.actions.signup({ firstName: firstName, lastName: lastName, email: email, password: password })
    .then(response => {
      this.props.history.push("/");
    }).catch(error => {
      this.setState({ error: 'This email is already registered.', submitted: false });
    })
  };

  render() {
    return (
      <Page>
        <Container>
          <div className="sub-title">Sign Up</div>
          <Form className="form" onSubmit={this.attemptSignup}>
            <FormGroup>
              <Input
                id="firstName"
                name="firstName"
                bsSize="lg"
                placeholder="First Name"
                value={this.state.firstName}
                onChange={this.handleChange}
                invalid={this.state.firstNameError}
                required
              />
              <FormFeedback>{this.state.firstNameErrorMessage}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Input
                id="lastName"
                name="lastName"
                bsSize="lg"
                placeholder="Last Name"
                value={this.state.lastName}
                onChange={this.handleChange}
                invalid={this.state.lastNameError}
                required
              />
              <FormFeedback>{this.state.lastNameErrorMessage}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Input
                id="email"
                name="email"
                type="email"
                bsSize="lg"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleChange}
                invalid={this.state.emailError}
                required
              />
              <FormFeedback>{this.state.emailErrorMessage}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Input
                id="password"
                name="password"
                type="password"
                bsSize="lg"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handleChange}
                invalid={this.state.passwordError}
                required
              />
              <FormFeedback>{this.state.passwordErrorMessage}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Input
                id="passwordConfirm"
                name="passwordConfirm"
                type="password"
                bsSize="lg"
                placeholder="Confirm Password"
                value={this.state.passwordConfirm}
                onChange={this.handleChange}
                invalid={this.state.passwordConfirmError}
                required
              />
              <FormFeedback>{this.state.passwordConfirmErrorMessage}</FormFeedback>
            </FormGroup>
            <Button color="primary" size="lg" block>Sign Up</Button>
          </Form>
          <div className="error">{this.state.error}</div>
          <div className="bottom">
            <div className="sub-text">Already have an account? <Link to="/" className="link">Sign in here.</Link></div>
          </div>
        </Container>
      </Page>
    )
  }
}

const Page = styled.div`
	display: flex;
	position: relative;
  padding: 40px 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-height: 100vh;
  background: url('https://source.unsplash.com/user/eberhardgross/1920x1080') no-repeat center center fixed;
  background-size: cover;
  color: #2d3039;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 50px;
  width: 100%;
  flex-shrink: 0;
  max-width: 500px;
  border-radius: 5px;
  background: #FAFAFA;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
  color: #2d3039;

  .sub-title {
    font-weight: lighter;
    font-size: 2em;
    margin: 20px 0;
    text-align: center;
  }

  .form {
    width: 100%;
  }

  .error {
    margin: 10px 0;
    margin-top: 15px;
    color: #f44336;
    font-weight: lighter;
  }

  .bottom {
    margin: 15px 0;
    width: 100%;
    text-align: center;

    .sub-text {
      font-weight: lighter;
      margin-top: 20px;
    }

    .link {
      color: #2196f3;
    }
  }
`;

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ signup }, dispatch)

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));