import React from "react";
import styled from 'styled-components';
import {connect} from 'react-redux';
import {notify} from 'reapop';
import { Button, Label, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { updateOrder } from '../../../actions/orderActions';


class ModalContainer extends React.Component {

  state = {
    salesMan: '',
    super: '',
    superPhone: '',
    serviceArea: '',
    dropOffLocation: ''
  }

  initializeState = () => {
    const order = this.props.order;
    this.setState({
      salesMan: order.salesMan,
      super: order.super,
      superPhone: order.superPhone,
      serviceArea: order.serviceArea,
      dropOffLocation: order.dropOffLocation
    });
  };

  handleChange = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
  }

  update = () => {
    updateOrder(this.props.order._id, this.state).then(response => {
      const currentState = this.state;
      this.props.notify({ title: 'Updated', message: `Order ${response.data.orderNumber} sucessfully updated.`, status: 'success', position: 'bl' });
      this.props.update(currentState);
      this.props.toggle();
    }).catch(error => this.props.notify({ status: 'error', position: 'bl', title: `${error.response.status}`, message: `${error.response.statusText}` }))
  }

	render() {
    const { toggle, show } = this.props;

		return (
      <CustomModal isOpen={show} toggle={toggle} onOpened={this.initializeState} centered>
        <ModalHeader className="header" toggle={toggle}></ModalHeader>
        <ModalBody className="body">
          <div className="title">Edit Order</div>
          <FormGroup>
            <Label className="label" for="salesman">Salesman</Label>
            <Input 
              id="salesman"
              name="salesMan"
              bsSize="lg"
              placeholder="Salesman"
              value={this.state.salesMan}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label className="label" for="super">Superintendent</Label>
            <Input 
              id="super"
              name="super"
              bsSize="lg"
              placeholder="superintendent"
              value={this.state.super}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label className="label" for="superPhone">Superintendent Phone</Label>
            <Input 
              id="superPhone"
              name="superPhone"
              type="phone"
              bsSize="lg"
              placeholder="Superintendent Phone"
              value={this.state.superPhone}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label className="label" for="serviceArea">Service Area</Label>
            <Input 
              id="serviceArea"
              name="serviceArea"
              bsSize="lg"
              placeholder="Service Area"
              value={this.state.serviceArea}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label className="label" for="dropOffLocation">Drop Off Location</Label>
            <Input 
              id="dropOffLocation"
              name="dropOffLocation"
              bsSize="lg"
              placeholder="Drop Off Location"
              value={this.state.dropOffLocation}
              onChange={this.handleChange}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="flex-foot">
          <Button className="button" color="primary" size="lg" onClick={this.update}>Save Changes</Button>
        </ModalFooter>
      </CustomModal>
		)
	}
}

const CustomModal = styled(Modal)`
  color: #707070;

  .header, .flex-foot {
    border-bottom: none;
    border-top: none;
  }
  
  .header {
    padding-bottom: 0;
  }

  .title {
    color: #444444;
    font-size: 1.8em;
  }

  .label {
    color: #444444;
    font-size: 0.8em;
  }

  .body {
    padding: 0 1rem;
  }

  .flex-foot {
    display: flex;
    align-items: center;
    .button {
      flex: 1;
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 0.9em;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
`;

export default connect(null, {notify})(ModalContainer);