import { axiosInstance } from './axios';

export function getUsers() {
	const token = localStorage.getItem('token');
	return axiosInstance.get('users', { headers: { 'Authorization': token } })
}

export function updateUser(user) {
	return axiosInstance.put('users/' + user._id , user);
}

export function deleteUser(id) {
	return axiosInstance.delete('users/' + id);
}