import React from 'react';
import styled from 'styled-components';

const Areas = ({ areas }) => (
    <AreaContainer>
        { areas.map((o, i) => (
            <AreaBlock key={i}>
                <h1>{ o.AREA }</h1>
                <p className="finish" >{ o.Finish }</p>
                <p className="facemat">{ o.FACEMAT }</p>
                <p><span>Series</span> { o.Series }</p>
                <p><span>Door Style</span> { o["Door Style"] }</p>
                <p><span>Original Order</span> { o.OriginalNo }</p>
            </AreaBlock>
        ))}
    </AreaContainer>
)


const AreaContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const AreaBlock = styled.div`
    width: calc(25% - 10px);
    text-align: center;
    background-color: #424242;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 20px 20px;

    @media (max-width: 1200px) {
        width: calc(50% - 10px);
    }

    @media (max-width: 550px) {
        width: 100%;
    }

    h1 {
        font-weight: bolder;
        font-size: 1em;
    }
    .finish {
        font-weight: bolder;
        font-size: 1em;
    }
    .facemat {
        font-weight: bolder;
        font-size: 1em;
    }
    p {
        text-transform: uppercase;
        font-size: .7em;
        font-weight: bolder;
        margin-bottom: 2px;
        span {
            font-weight: lighter;
            margin-right: 10px;
        }
    }
`;

export default Areas;