import React from 'react';
import styled from 'styled-components';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@material-ui/core';
import { updateSale, createSale } from '../../../actions/salesActions';
var moment = require('moment-timezone');


class NewSale extends React.Component {


	state = {
		orderAddress: '',
		customer: '',
		orderNumber: 0,
		bidIn: '',
		bidOut: '',
		drawIn: '',
		drawOut: '',
		revisions: '',
		signOff: '',
		release: '',
		production: '',
		deliver: '',	
	}

	

	fill = () => {
		this.setState({
			orderAddress: '',
			customer: '',
			orderNumber: '',
			bidIn: '',
			bidOut: '',
			drawIn: '',
			drawOut: '',
			revisions: '',
			signOff: '',
			release: '',
			production: '',
			deliver: '',	
		});

		if (this.props.sale) this.setState({ ...this.props.sale });
	}

	handleChange = (event) => {
		let name = event.target.name;
    this.setState({ [name]: event.target.value });
	}

	handleTimeChange = (event) => {
		let name = event.target.name;
    this.setState({ [name]: moment(event.target.value).tz('CST6CDT') });
	}
	
	save = () => {
		var saleCall = createSale;

		if (this.props.sale) saleCall = updateSale;

		saleCall(this.state).then(response => {
			this.props.handleClose();
    }).catch(error => console.log(error.response))
	}

	render() {
		const { open, handleClose } = this.props;
		const { orderAddress, customer, orderNumber, bidIn, bidOut, drawIn, drawOut, revisions, signOff, release, production, deliver } = this.state;
		return (
			<Dialog open={open} onClose={handleClose} onEnter={this.fill} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Sale</DialogTitle>
				<DialogContent>
					<CustomTextField
						name="orderAddress"
						label="Order Address"
						type="text"
						value={orderAddress}
						onChange={this.handleChange}
						fullWidth
					/>
					<CustomTextField
						name="customer"
						label="Customer"
						type="text"
						value={customer}
						onChange={this.handleChange}
						fullWidth
					/>
					<CustomTextField
						name="orderNumber"
						label="Order Number"
						type="number"
						value={orderNumber}
						onChange={this.handleChange}
						fullWidth
					/>
					<CustomTextField
						name="drawIn"
						label="Draw In"
						type="date"
						value={moment(drawIn).format('YYYY-MM-DD')}
						onChange={this.handleTimeChange}
						InputLabelProps={{
							shrink: true,
						}}
					/><br/>
					<CustomTextField
						name="drawOut"
						label="Draw Out"
						type="date"
						value={moment(drawOut).format('YYYY-MM-DD')}
						onChange={this.handleTimeChange}
						InputLabelProps={{
							shrink: true,
						}}
					/><br/>
					<CustomTextField
						name="bidIn"
						label="Bid In"
						type="date"
						value={moment(bidIn).format('YYYY-MM-DD')}
						onChange={this.handleTimeChange}
						InputLabelProps={{
							shrink: true,
						}}
					/><br/>
					<CustomTextField
						name="bidOut"
						label="Bid Out"
						type="date"
						value={moment(bidOut).format('YYYY-MM-DD')}
						onChange={this.handleTimeChange}
						InputLabelProps={{
							shrink: true,
						}}
					/><br/>
					<CustomTextField
						name="revisions"
						label="Revisions"
						type="date"
						value={moment(revisions).format('YYYY-MM-DD')}
						onChange={this.handleTimeChange}
						InputLabelProps={{
							shrink: true,
						}}
					/><br/>
					<CustomTextField
						name="signOff"
						label="Sign Off"
						type="date"
						value={moment(signOff).format('YYYY-MM-DD')}
						onChange={this.handleTimeChange}
						InputLabelProps={{
							shrink: true,
						}}
					/><br/>
					<CustomTextField
						name="release"
						label="Release"
						type="date"
						value={moment(release).format('YYYY-MM-DD')}
						onChange={this.handleTimeChange}
						InputLabelProps={{
							shrink: true,
						}}
					/><br/>
					<CustomTextField
						name="production"
						label="Production"
						type="date"
						value={moment(production).format('YYYY-MM-DD')}
						onChange={this.handleTimeChange}
						InputLabelProps={{
							shrink: true,
						}}
					/><br/>
					<CustomTextField
						name="deliver"
						label="Deliver"
						type="date"
						value={moment(deliver).format('YYYY-MM-DD')}
						onChange={this.handleTimeChange}
						InputLabelProps={{
							shrink: true,
						}}
					/><br/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>
					<Button onClick={this.save} color="primary">
						Save
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

const CustomTextField = styled(TextField)`
  margin-top: 10px !important;
`;

export default NewSale;