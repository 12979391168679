import React, { Component } from "react";
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

class PrivateRoute extends Component {

	render() {
		const { component: Component, ...props } = this.props;
		return (
			<Route
				{...props}
				render={props => (
					this.props.isAuthenticated ?
						<Component {...this.props} />
						: <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
				)} />
		)
	}
}

const mapStateToProps = state => ({
	isAuthenticated: state.authReducer.isAuthenticated,
	firstName: state.authReducer.firstName,
	lastName: state.authReducer.lastName,
	email: state.authReducer.email,
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);