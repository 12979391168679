import React from 'react';
import styled from 'styled-components';
import { getSales } from '../../actions/salesActions';
import { chain, zipObject } from 'lodash';

import SalesTable from './components/table.component';
import NewSale from './components/newSale.component';

import Button from '@material-ui/core/Button';

class Page extends React.Component {

	state = {
		clients: [],
		modal: false,
		sale: null
	}

	componentDidMount() {
    getSales().then(response => {

			var clients = chain(response.data)
        .groupBy("client")
        .toPairs()
        .map((currentItem) => {
          return zipObject(["client", "sales"], currentItem);
        })
        .value();

				console.log(clients)

      this.setState({ clients: clients });
    }).catch(error => console.log(error.response))
	}

	toggleState = () => {
		if (this.state.modal) this.setState({ sale: null });
		this.setState({ modal: !this.state.modal });
	}

	editSale = (sale) => {
		this.setState({ modal: true, sale: sale });
	}
	
	render() {
		const { clients, modal, sale } = this.state;
		return (
			<Container>
				<NewSale sale={sale} open={modal} handleClose={this.toggleState} />
				<StyledButton onClick={this.toggleState}>Create New Order</StyledButton>
				<hr />
				{ clients.map((client, i) => (
					<SalesTable key={i} edit={this.editSale} data={client.sales} title={client.client} />
				))}
			</Container>
		)
	}
}

const Container = styled.div`
    width: 100%;
    min-height: 400px;
    max-width: 1400px;
    margin: 0 auto;
    margin-top: 30px;
    padding: 0px 30px;
`;

const StyledButton = styled(Button)`
  background-color: #2ec1a6 !important;
  text-transform: none !important;
  border-radius: 3px;
  border: 0;
  color: #fff !important;
  padding: 0px 20px !important;
  height: 48px;
  padding: 0 30px;
  margin-right: 20px !important;
  box-shadow: 0 3px 5px 2px rgba(100, 255, 240, .3);
  padding: 0px 10px;
`;

export default Page;