import React from "react";
import styled from 'styled-components';
import dayjs from "dayjs"
import {connect} from 'react-redux';
import {notify} from 'reapop';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

class ModalContainer extends React.Component {

	render() {
    const { toggle, show, attachments} = this.props;

		return (
      <CustomModal isOpen={show} toggle={toggle} centered>
        <ModalHeader className="header" toggle={toggle}></ModalHeader>
        <ModalBody className="body">
          <div className="title">Attachments</div>
          {attachments.map((attachment, i) => (
            <Attachment key={attachment.fileID}>
              <div className="identifier">{attachment.fileIdentifier}</div>
              <div className="file-name">{attachment.fileName}</div>
              <div className="info">{attachment.user} - </div>
              <div className="info"> {dayjs(attachment.created).format("MMM D, h:mm a")}</div><br/>
              <a href={`https://api.benecab.io/v1/attachments/download/${attachment.fileID}`} rel="noopener noreferrer" target="_blank"><Button color="primary">View</Button></a>
            </Attachment>
          ))}
        </ModalBody>
        <ModalFooter className="flex-foot" />
      </CustomModal>
		)
	}
}

const CustomModal = styled(Modal)`
  color: #707070;

  .header, .flex-foot {
    border-bottom: none;
    border-top: none;
  }
  
  .header {
    padding-bottom: 0;
  }

  .title {
    color: #444444;
    font-size: 1.8em;
    margin-bottom: 20px;
  }

  .body {
    padding: 0 1rem;
  }

  .flex-foot {
    display: flex;
    align-items: center;
  }
`;

const Attachment = styled.div`
  margin-bottom: 20px;

  .identifier {
    color: #444444;
    font-size: 1.4em;
  }

  .file-name {
    font-size: 1.2em;
  }

  .info {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 10px;
  }
`;

export default connect(null, {notify})(ModalContainer);