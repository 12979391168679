import React from "react";
import Routes from './routes';

class Page extends React.Component {
	render() {
		return (
			<Routes />
		)
	}
}


export default Page;