import React from "react";
import styled from 'styled-components';
import Map from '../components/map';

const Container = styled.div`
	width: 100%;
	position: relative;
	background: #f4f7fc;
	color: #2d3039;
	padding: 40px;
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
`;

class Location extends React.Component {

	render() {
		return (
			<Container>
        <Map />
			</Container>
		)
	}
}

export default Location