import React from "react";
import styled from 'styled-components';
import {connect} from 'react-redux';
import dayjs from "dayjs";
import {notify} from 'reapop';
import { sortBy } from 'lodash';
import { Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem } from 'reactstrap';


class ModalContainer extends React.Component {

  state = {
    statusLog: []
  }

  initializeState = () => {
    const item = this.props.item;
    this.setState({
      statusLog: sortBy(item.statusLog, 'date').reverse()
    });
  };

  handleChange = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
  }

  update = () => {
  }

	render() {
    const { toggle, show } = this.props;

		return (
      <CustomModal isOpen={show} toggle={toggle} onOpened={this.initializeState} centered>
        <ModalHeader className="header" toggle={toggle}></ModalHeader>
        <ModalBody className="body">
          <div className="title">Item History</div>
          <ListGroup>
            {this.state.statusLog.map((status, i) => (
              <ListGroupItem className="list-item" key={status._id}>{status.user}<div class="date">{dayjs(status.date).format("MMM D, h:mm a")}</div> <div className="status">{status.status}</div></ListGroupItem>
            ))}
          </ListGroup>
        </ModalBody>
        <ModalFooter className="flex-foot">
        </ModalFooter>
      </CustomModal>
		)
	}
}

const CustomModal = styled(Modal)`
  color: #707070;

  .header, .flex-foot {
    border-bottom: none;
    border-top: none;
  }
  
  .header {
    padding-bottom: 0;
  }

  .title {
    color: #444444;
    font-size: 1.8em;
  }

  .body {
    padding: 0 1rem;
  }

  .list-item {
    font-size: 0.8em;

    .date {
      display: inline-block;
      float: right;
      background: #007bff;
      color: white;
      border-radius: 3px;
      padding: 2px 5px;
      width: 110px;
      text-align: center;
      margin-right: 5px;
    }

    .status {
      margin-top: 5px;
      font-size: 1.5em;
      font-weight: bold;
    }
  }
`;

export default connect(null, {notify})(ModalContainer);