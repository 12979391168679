import React from "react";
import Navbar from '../../components/navbar'
import Container from '../../components/container'
import Users from './pages/'

class Page extends React.Component {
	render() {
		return (
			<Container direction="column">
				<Navbar />
				<Users />
			</Container>
		)
	}
}


export default Page;