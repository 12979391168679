import { axiosInstance } from './axios';

const url = 'items';

export function updateItemStatus(icn, status) {
	return axiosInstance.put(`${url}/statusUpdate/${icn.substring(3)}`, {status: status, onShelf: true});
}

export function getItemsByOrderNumber(orderNumber) {
	return axiosInstance.get(`${url}/?orderNumber=${orderNumber}`);
}

export function getOrderFromICN(icn) {
	return axiosInstance.get(`${url}/getOrderFromICN/${icn.substring(3)}`);
}

export function addCustomItem(orderNumber, qty, itemDescription) {
	return axiosInstance.post(url, {orderNumber: orderNumber, qty: qty, itemDescription: itemDescription});
}

export function getLoadedItems(date) {
	return axiosInstance.get(`${url}/reports/loadedToday/${date}`);
}