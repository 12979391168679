import React from "react";
import styled from 'styled-components';
import { updateUser, deleteUser } from '../../../actions/userActions';
import {connect} from 'react-redux';
import {notify} from 'reapop';
import { Button, Label, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Switch from 'rc-switch';

class ModalContainer extends React.Component {

  state = {
    email: '',
    firstName: '',
    lastName: '',
    role: '',
    department: '',
    isActive: false
  }

  setForm = () => {
    this.setState(this.props.user);
  };

  handleChange = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
  }

  handleCheckChange = (value, event) => {
    this.setState({ isActive: value });
  }

  updateUser = () => {
    updateUser(this.state).then(response => {
      this.props.notify({ title: 'Updated', message: `${response.data.email} sucessfully updated!`, status: 'success', position: 'bl' });
      this.props.updateUser(response.data);
      this.props.toggle();
    }).catch(error => this.props.notify({ status: 'error', position: 'bl', title: `${error.response.status}`, message: `${error.response.statusText}` }))
  }

  deleteUser = () => {
    deleteUser(this.state._id).then(response => {
      this.props.notify({ title: 'Deleted', message: `${response.data.email} sucessfully deleted!`, status: 'success', position: 'bl' });
    }).catch(error => this.props.notify({ status: 'error', position: 'bl', title: `${error.response.status}`, message: `${error.response.statusText}` }))
  }

	render() {
    const { toggle, show } = this.props;
		return (
      <CustomModal isOpen={show} toggle={toggle} centered onOpened={this.setForm}>
        <ModalHeader className="header" toggle={toggle}></ModalHeader>
        <ModalBody className="body">
          <div className="title">Edit User</div>
          <FormGroup>
            <Label className="label" for="email">Email</Label>
            <Input 
              id="email"
              name="email"
              type="email"
              bsSize="lg"
              placeholder="Email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          <Row>
            <FormGroup>
              <Label className="label" for="firstName">First Name</Label>
              <Input 
                id="firstName"
                name="firstName"
                type="text"
                bsSize="lg"
                placeholder="First Name"
                value={this.state.firstName}
                onChange={this.handleChange}
              />
            </FormGroup>
            <div className="divider"></div>
            <FormGroup>
              <Label className="label" for="lastName">Last Name</Label>
              <Input 
                id="lastName"
                name="lastName"
                type="text"
                bsSize="lg"
                placeholder="Last Name"
                value={this.state.lastName}
                onChange={this.handleChange}
              />
            </FormGroup>
          </Row>
          <FormGroup>
            <Label className="label" for="role">Role</Label>
            <Input 
              type="select"
              name="role"
              id="role"
              bsSize="lg"
              value={this.state.role}
              onChange={this.handleChange}
            >
              <option value={5}>User</option>
              <option value={2}>Admin</option>
              <option value={1}>Super</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label className="label" for="department">Department</Label>
            <Input 
              type="select"
              name="department"
              id="department"
              bsSize="lg"
              value={this.state.department}
              onChange={this.handleChange}
            >
              <option>Select One</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label className="label" for="isActive">Activated</Label><br/>
            <Switch
              onChange={this.handleCheckChange}
              checked={this.state.isActive}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="flex-foot">
          <Button className="button" color="danger" size="lg" onClick={this.deleteUser}>Delete User</Button>
          <Button className="button" color="primary" size="lg" onClick={this.updateUser}>Save Changes</Button>
        </ModalFooter>
      </CustomModal>
		)
	}
}

const CustomModal = styled(Modal)`
  color: #707070;

  .header, .flex-foot {
    border-bottom: none;
    border-top: none;
  }
  
  .header {
    padding-bottom: 0;
  }

  .title {
    color: #444444;
    font-size: 1.8em;
  }

  .label {
    color: #444444;
    font-size: 0.8em;
  }

  .body {
    padding: 0 1rem;
  }

  .flex-foot {
    display: flex;
    align-items: center;
    .button {
      flex: 1;
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 0.9em;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
`;

const Row = styled.div`
  display: flex;

  .divider {
    width: 10px;
  }
`;

export default connect(null, {notify})(ModalContainer);