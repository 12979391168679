import React from "react";
import styled from 'styled-components';
import dayjs from "dayjs"
import {connect} from 'react-redux';
import {notify} from 'reapop';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModalContainer extends React.Component {

	render() {
    const { toggle, show, notes } = this.props;

		return (
      <CustomModal isOpen={show} toggle={toggle} centered>
        <ModalHeader className="header" toggle={toggle}></ModalHeader>
        <ModalBody className="body">
          <div className="title">Notes</div>
          {notes.map((note, i) => (
            <Note key={note._id} >
              <div className="note">
                {note.note}
              </div>
              <div className="note-info">
                {note.user}
                <div className="grow" />
                {dayjs(note.noteCreated).format("MMM D, h:mm a")}
              </div>
            </Note>
          ))}
        </ModalBody>
        <ModalFooter className="flex-foot" />
      </CustomModal>
		)
	}
}

const CustomModal = styled(Modal)`
  color: #707070;

  .header, .flex-foot {
    border-bottom: none;
    border-top: none;
  }
  
  .header {
    padding-bottom: 0;
  }

  .title {
    color: #444444;
    font-size: 1.8em;
    margin-bottom: 20px;
  }

  .body {
    padding: 0 1rem;
  }

  .flex-foot {
    display: flex;
    align-items: center;
  }
`;

const Note = styled.div`

  .note-info {
    color: #bbbbbb;
    display: flex;
    font-size: 0.8em;
    margin-bottom: 20px;

    .grow {
      flex: 1;
    }
  }
`;

export default connect(null, {notify})(ModalContainer);