import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import Home from "./pages/"
import Order from "./pages/order"
import Scan from "./pages/scan"
import SampleMatch from "./pages/sampleMatch"
import ShippingReport from "./pages/shippingReport";

class Routes extends Component  {
  render() {
    return(
      <Switch>
        <Route
          exact
          path="/warranty" 
          component={Home}
        />
        <Route
          exact
          path="/warranty/scan" 
          component={Scan}
        />
        <Route
          path="/warranty/reports/shippingReport/:date?"
          component={ShippingReport}
        />
        <Route 
          path="/warranty/order/:id" 
          component={Order}
        />
        <Route 
          path="/warranty/samplematch/:id?" 
          component={SampleMatch}
        />
      </Switch> 
    )
  }

}

export default Routes;