import React, { Component } from 'react';
import { connect } from 'react-redux';
import NotificationsSystem from 'reapop';
import theme from 'reapop-theme-wybo';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { BrowserRouter as Router } from "react-router-dom";
import Routes from './routes';
import { getUser } from './actions/authenticationActions';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const materialTheme = createMuiTheme({
  palette: {
		primary: {
			main: '#0281f9'
		},
		secondary: {
			main: '#dd3333'
		}
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Rubik'
    ]
  },
});

const Loader = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 250px;
  }
`;


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false
    };  
  }

  componentDidMount() {

    if (!localStorage.getItem('token')) return this.setState({ isLoaded: true });
    this.props.actions.getUser().then(response => {
      this.setState({ isLoaded: true });
    }).catch(error => {
      this.setState({ isLoaded: true });
    })
  }
  render() {
    const { isLoaded } = this.state;
    return (
      isLoaded ?
      <MuiThemeProvider theme={materialTheme}>
        <NotificationsSystem theme={theme}/>
        <Router>
          <Routes />
        </Router>
      </MuiThemeProvider> :
      <Loader><img src="/loader.gif" alt=""/></Loader>
      
    );
  }
}

const mapStateToProps = state => ({
  
})
const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({ getUser }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(App);