import React from 'react';
import Navbar from '../../components/navbar'
import Home from './pages/';
import Container from '../../components/container'

class Dashboard extends React.Component {
	render() {
		return (
			<Container direction="column">
				<Navbar />
				<Home />
			</Container>
		)
	}
}

export default Dashboard;