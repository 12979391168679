import React from "react";
import styled from 'styled-components';
import {connect} from 'react-redux';
import {notify} from 'reapop';
import { Button, Label, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { addCustomItem } from '../../../actions/itemActions';


class ModalContainer extends React.Component {

  state = {
    orderNumber: null,
    qty: 1,
    itemDescription: ''
  }

  initializeState = () => {
    const orderNumber = this.props.orderNumber;
    this.setState({
      orderNumber: orderNumber,
      qty: 1,
      itemDescription: ''
    });
  };

  handleChange = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
  }

  addItem = () => {
    if (this.state.qty < 1 || this.state.itemDescription === '') return;
    addCustomItem(this.state.orderNumber, this.state.qty, this.state.itemDescription).then(response => {
      this.props.notify({ title: 'Item Added', message: `Sucessfully added ${this.state.qty} items to Order ${response.data.orderNumber}.`, status: 'success', position: 'bl' });
      this.props.update(response.data);
      this.props.toggle();
    }).catch(error => this.props.notify({ status: 'error', position: 'bl', title: `${error.response.status}`, message: `${error.response.statusText}` }))
  }

	render() {
    const { toggle, show } = this.props;

		return (
      <CustomModal isOpen={show} toggle={toggle} onOpened={this.initializeState} centered>
        <ModalHeader className="header" toggle={toggle}></ModalHeader>
        <ModalBody className="body">
          <FormGroup>
            <Label className="label" for="qty">Quantity</Label>
            <Input 
              id="qty"
              name="qty"
              bsSize="lg"
              type="number"
              placeholder="quantity"
              value={this.state.qty}
              onChange={this.handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label className="label" for="itemDescription">Item Description</Label>
            <Input 
              id="itemDescription"
              name="itemDescription"
              bsSize="lg"
              placeholder="Item Description"
              value={this.state.itemDescription}
              onChange={this.handleChange}
              required
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="flex-foot">
          <Button className="button" color="primary" size="lg" onClick={this.addItem}>Add Item</Button>
        </ModalFooter>
      </CustomModal>
		)
	}
}

const CustomModal = styled(Modal)`
  color: #707070;

  .header, .flex-foot {
    border-bottom: none;
    border-top: none;
  }
  
  .header {
    padding-bottom: 0;
  }

  .title {
    color: #444444;
    font-size: 1.8em;
  }

  .label {
    color: #444444;
    font-size: 0.8em;
  }

  .body {
    padding: 0 1rem;
  }

  .flex-foot {
    display: flex;
    align-items: center;
    .button {
      flex: 1;
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 0.9em;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
`;

export default connect(null, {notify})(ModalContainer);