import React from 'react';
import moment from 'moment';
import Styled from 'styled-components';

const TimeStamp = ({ date }) => (
    <Stamp>Last Updated: <strong>{moment(date).calendar()}</strong></Stamp>
)

export default TimeStamp;

const Stamp = Styled.p`
    text-transform: uppercase;
    font-size: .5em;
    letter-spacing: 2px;
    margin-left: 55px;
    text-align: center;
`;