import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { CheckCircle, NotInterested } from 'styled-icons/material';

const SalesTable = ({ edit, data, title }) => (
	<Holder>
		
		<h1>{title}</h1>
		<NewPaper elevation={6}>
			<Table>
				<StyledHead>
					<TableRow>
						<TableCell>Address</TableCell>
						<TableCell align="right">Client</TableCell>
						<TableCell align="right">Drawn</TableCell>
						<TableCell align="right">Bid</TableCell>
						<TableCell align="right">Signed Off</TableCell>
						<TableCell align="right">Released</TableCell>
						<TableCell align="right">Delivered</TableCell>
						<TableCell align="right">Current Status</TableCell>
					</TableRow>
				</StyledHead>
				<TableBody>
					{data.map((row, i) => (
						<CustomTableRow key={i} onClick={() => edit(row)}>
							<WideCell component="th" scope="row">{row.orderAddress}</WideCell>
							<TableCell align="right">{row.customer}</TableCell>
							<TableCell align="right">{!row.drawIn && !row.drawOut ? (<TableNotInterested size={20} />) : null}{row.drawIn && !row.drawOut ?  moment(row.drawIn).format('MM/DD') : null}{row.drawOut ? (<TableCheck size={20} />) : null}</TableCell>
							<TableCell align="right">{!row.bidIn && !row.bidOut ? (<TableNotInterested size={20} />) : null}{row.bidIn && !row.bidOut ?  moment(row.bidIn).format('MM/DD') : null}{row.bidOut ? (<TableCheck size={20} />) : null}</TableCell>
							<TableCell align="right">{row.signOff ? moment(row.signOff).format('MM/DD') : null }</TableCell>
							<TableCell align="right">{row.release ? moment(row.release).format('MM/DD') : null }</TableCell>
							<TableCell align="right">{row.deliver ? moment(row.deliver).format('MM/DD') : null }</TableCell>
							<TableCell align="right">{row.status}</TableCell>
						</CustomTableRow>
					))}
				</TableBody>
			</Table>
		</NewPaper>
	</Holder>
)

const CustomTableRow = styled(TableRow)`
	cursor: pointer;
	transition: 0.2s all ease-in-out;

	&:hover {
		background: rgba(0, 0, 0, 0.1);
	}
`;

const Holder = styled.div`
    margin-top: 40px;
    margin-bottom: 50px;
`;

const NewPaper = styled(Paper)`
    overflow-x: auto;
`;

const StyledHead = styled(TableHead)`
    background-color: #232323;
    th {
        color: #fff;
        font-weight: bolder;
    }
`;

const WideCell = styled(TableCell)`
    min-width: 300px;
`;

const TableCheck = styled(CheckCircle)`
    color: #2ec1a6
`;

const TableNotInterested = styled(NotInterested)`
    color: #ff8e53;
`;

export default SalesTable;