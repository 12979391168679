import io from 'socket.io-client';
const socket = io('https://api.benecab.io');

export function newUser(callback) {
    socket.on('newUser', user => callback(null, user));
}

export function userUpdated(callback) {
    socket.on('userUpdated', user => callback(null, user));
}

export function itemUpdated(callback) {
  socket.on('itemUpdated', item => callback(null, item));
}

export function saleUpdated(callback) {
  socket.on('newSaleUpdated', item => callback(null, item));
}