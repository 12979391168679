import React, { Component } from "react";
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  position: relative;
  align-items: ${props => props.align};
  justify-content: ${props => props.justify};
  flex-direction: ${props => props.direction};
  background: url(${props => props.backgroundImage}) no-repeat center center fixed;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
`;

class PageContainer extends Component {
  render() {
    return (
      <Container align={this.props.align} padding={this.props.padding} justify={this.props.justify} direction={this.props.direction} backgroundImage={this.props.backgroundImage}>
        {this.props.children}
      </Container>
    )
  }
}

export default PageContainer;