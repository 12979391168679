import React from "react";
import styled from 'styled-components';
import { Users, UserPlus, Lock, Activity, List } from 'react-feather';
import { filter, sortBy } from 'lodash'
import UserTable from '../components/users'
import { getUsers } from '../../../actions/userActions';
import { newUser, userUpdated } from '../../../actions/socket';


const Container = styled.div`
	width: 100%;
	position: relative;
	background: #f4f7fc;
	color: #2d3039;
	padding: 40px;
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
`;

const Title = styled.div`
	font-size: 2em;
	margin: 40px 0;
	display: flex;
	align-items: center;
`;

const Cards = styled.div`
	flex-shrink: 0;
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;

	@media (max-width: 870px) {
		flex-wrap: wrap;
		
		.card {
			width: 100% !important;
		}
	}


	.card {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 20px;
		height: 200px;
		width: 300px;
		background: white;
		border-radius: 3px;
		box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
	}

	.card-flex {
		display: flex;
		align-items: center;
		padding: 40px;
		
		.info {
			font-weight: lighter;
			font-size: 1.5em;
			margin-left: 20px;
			text-align: center;

			.value {
				font-weight: bold;
				font-size: 3em;
			}
		}
	
	}
`;

class UserDashboard extends React.Component {

	state = {
		users: []
	}


	componentDidMount() {
    getUsers().then(response => {
      this.setState({ users: response.data });
    }).catch(error => console.log(error.response))

    userUpdated((err, user) => this.updateUser(user));

    newUser((err, user) => this.setState({users: [...this.state.users, user]}));

	}
	
	updateUser = (newUser) => {
    this.setState({ users: this.state.users.map(user => (user._id === newUser._id) ? newUser : user) });
  }


	render() {
		const { users } = this.state;
		return (
			<Container>
				<Title><Users size="1em" />&nbsp;&nbsp;User Dashboard</Title>
				<Cards>
					<div className="card">
						<div className="card-flex">
							<UserPlus size="5em" color="#0281f9"/>
							<div className="info">
								<div className="value">5</div>
								<div className="title">new users</div>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="card-flex">
							<Lock size="5em" color="#ed3938"/>
							<div className="info">
								<div className="value">{ filter(users, ['isActive', false]).length}</div>
								<div className="title">inactive users</div>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="card-flex">
							<Activity size="5em" color="#32c431"/>
							<div className="info">
								<div className="value">{ filter(users, 'isActive').length}</div>
								<div className="title">active users</div>
							</div>
						</div>
					</div>
				</Cards>
				<Title><List size="1em" />&nbsp;&nbsp;User List</Title>
				<UserTable users={sortBy(users, ['role', 'firstName'])} updateUser={this.updateUser}/>

			</Container>
		)
	}
}

export default UserDashboard