import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Container, TextField } from '@material-ui/core';

import { getSampleMatches, getAreas } from '../../../actions/sampleMatchActions';

import SampleBlocks from '../components/samples.component';
import Areas from '../components/areas.component';


class Samplematch extends React.Component {

	state = {
		loading: false,
		firstSearch: true,
		searchOrderNumber: "",
		orderNumber: "",
		areas: [],
		samples: []
	}

	searchForOrder(orderNumber) {
		this.setState({ loading: true, firstSearch: false, areas: [], samples: [], orderNumber: "", searchOrderNumber: "" });
		getSampleMatches(orderNumber).then(res => {
			this.setState({ samples: res.data, orderNumber: orderNumber })
		}).catch(err => {
			this.setState({ loading: false })
		})
		getAreas(orderNumber).then(res => {
			this.setState({ areas: res.data, loading: false })
		}).catch(err => {
			this.setState({ loading: false })
		})
	}

	render() {
		const { loading, searchOrderNumber, orderNumber, samples, areas, firstSearch } = this.state;
		return (
			<div>
				<DarkDiv>
					<Container>
						<TopTitle>Sample Match <span>Report</span></TopTitle>
						<LightTextfield
							autoFocus
							id="standard-name"
							label="Order Number"
							margin="normal"
							value={searchOrderNumber}
							onChange={(e) => { this.setState({ searchOrderNumber: e.target.value })}}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									this.searchForOrder(searchOrderNumber)
								}
							}}
						/>
						<Divider />
						{
							firstSearch ? (<p>Ready for Search</p>) : loading ? (<p>Loading</p>) : (
								<div>
									<h1>{ orderNumber }</h1>
									<TopTitle>Areas</TopTitle>
									<Areas areas={areas} />
									<TopTitle>Samples</TopTitle>
									<SampleBlocks samples={samples} />
								</div>
							)
						}
					</Container>
				</DarkDiv>
			</div>
		)
	}
}

/*

{
							(loading || areas.length == 0) ? (<p>Loading...</p>) : (
							<div>
									<h1>{ orderNumber }</h1>
									<TopTitle>Areas</TopTitle>
									<Areas areas={areas} />
									<TopTitle>Samples</TopTitle>
									<SampleBlocks samples={samples} />
								</div>
							)
						}

*/

const DarkDiv = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: 100%; 
	padding: 0;
	margin: 0;
	background-color: #191919;
	color: #fff;
`;

const TopTitle = styled.h1`
	margin-top: 40px;
	font-size: 1em;
	letter-spacing: 10px;
	font-weight: lighter;
	text-transform: uppercase;
	span {
		font-weight: 900;
	}
`;

const Divider = styled.hr`
	border-color: rgba(255,255,255,.2);
`;

const LightTextfield = styled(TextField)`
	label {
		color: #fff;
	}
	div {
		color: #fff;
		::before {
			border-color: #fff;
		}
	}
`;

export default Samplematch;