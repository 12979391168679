import React from 'react';
import Styled from 'styled-components';

const Counter = ({ counts }) => (
    <Holder>
        <Side assembly>
            <h4>ASSEMBLY</h4>
            <h1>{ counts.asmbox || 'N/A' }</h1>
        </Side>
        <Side>
            <h4>QC3</h4>
            <h1>{ counts.qc3box || 'N/A' }</h1>
        </Side>
    </Holder>
)

export default Counter;

const Holder = Styled.div`
    width: 100%;
    display: flex;
    flex: 1;
    height: calc(100vh - 100px);
    position: relative;
    background-color: green;
`;

const Side = Styled.div`
    width: 50%;
    height: 100%;
    background-color: ${props => props.assembly ? "#FF5100" : "#00AEFF" };
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: #fff;

    h4 {
        font-size: 3em;
        font-weight: lighter;
        letter-spacing: 2px;
        margin: 0;
    }

    h1 {
        font-size: 12em;
        margin: 0;
        font-weight: bolder;
    }
`;