import React from "react";
import ReactMapGL, {Popup} from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import {TripsLayer} from '@deck.gl/geo-layers';
import { getLocations } from '../../../actions/locationActions';
import moment from 'moment';




class Page extends React.Component {

  state = {
    data: [
      {
        "waypoints": [
        ]
      }
    ],
    viewport: {
      latitude: 29.563430,
      longitude: -95.825860,
      zoom: 8,
      bearing: 0,
      pitch: 0
    }
  };

  componentDidMount() {
    getLocations().then(res => {
      var locations = res.data;

      var users = [];

      locations.map(location => {
        const waypoints = [];
        location.coords.map((waypoint, index) => {
          const timestamp = moment(waypoint.dateTime).unix();
          waypoints.push({
            coordinates: [
              waypoint.gps.longitude,
              waypoint.gps.latitude
            ],
            timestamp: index + 1
          });
        })
        users.push({
          user: location.user,
          waypoints: waypoints
        });
      })

      console.log(users);
      this.setState({data: users});





    }).catch(err => console.log(err))
  }

	render() {
    const {data} = this.state;
    const layers = [
      // new TripsLayer({id: 'line-layer', data}),
      new TripsLayer({
        id: 'trips-layer',
        data,
        // deduct start timestamp from each data point to avoid overflow
        getPath: d => d.waypoints.map(p => [p.coordinates[0], p.coordinates[1], p.timestamp - 1]),
        getColor: [253, 128, 93],
        opacity: 0.8,
        widthMinPixels: 5,
        rounded: true,
        trailLength: 10000000000000000000000000000000,
        currentTime: this.state.data[0].waypoints.length
      })
    ];
		return (
      <DeckGL
        initialViewState={this.state.viewport}
        controller={true}
        layers={layers}
      >
        <ReactMapGL
          {...this.state.viewport}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API}
          onViewportChange={(viewport) => this.setState({ viewport })}
        >
        </ReactMapGL>
      </DeckGL>
		)
	}
}


export default Page;