import React from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link, BarChart2 } from 'react-feather';
import Welcome from '../../../components/welcome'
import { Button } from 'reactstrap';

const Container = styled.div`
	width: 100%;
	position: relative;
	background: #f4f7fc;
	padding: 40px;
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
`;

const Title = styled.div`
	font-size: 1.5em;
	margin: 40px 0;
`;

const Cards = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;

	.title {
		display: flex;
		align-items: center;
		font-weight: 300;
		font-size: 1.5em;
	}

	.description {
		font-weight: lighter;
	}
`;

const Card = styled.div`
	background: white;
	padding: 30px 40px;
	border-radius: 3px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
`;

class Home extends React.Component {

	state = {
		notifications: 2
	}


	render() {
		return (
			<Container>
				<Welcome backgroundImage="/dashboard/welcome.jpg" title={`Welcome ${this.props.firstName}`} />
				<Title><Link/> Quick Links</Title>
				<Cards>
					<Card>
            <div className="title"><BarChart2/>&nbsp;&nbsp;Service Report</div>
            <p className="description">See all service techs data for this week.</p>
            <Button color="primary">Run Report</Button>
					</Card>
				</Cards>
			</Container>
		)
	}
}

const mapStateToProps = state => ({
	firstName: state.authReducer.firstName,
	lastName: state.authReducer.lastName,
})

export default connect(mapStateToProps, {})(Home);