import React from "react";
import Navbar from '../../components/navbar'
import Container from '../../components/container'
import Routes from './routes';
const links = [
  {
    name: 'Scan',
    link: '/warranty/scan',
  },
  {
    name: 'Reports',
    link: '/warranty/reports/shippingreport',
  },
  {
    name: 'Sample Matches',
    link: '/warranty/samplematch',
  },
]

class Page extends React.Component {
	render() {
		return (
			<Container direction="column">
				<Navbar color="white" background="#273c75" home="/warranty" links={links}/>
				<Routes />
			</Container>
		)
	}
}


export default Page;