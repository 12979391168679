import { axiosInstance } from './axios';

const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
const SET_USER = 'SET_USER'

function setAuthenticated(authenticated) {
	return {
		type: SET_AUTHENTICATED,
		isAuthenticated: authenticated
	}
}

function setUser(user) {
	return {
		type: SET_USER,
		email: user.email,
		firstName: user.firstName,
		lastName: user.lastName,
		role: user.role,
		token: user.token
	}
}

export function getUser() {
	return dispatch => {
		const token = localStorage.getItem('token');
		return axiosInstance.get(`auth/me`, { headers: { 'Authorization': token } }).then(response => {
			dispatch(setAuthenticated(true));
			dispatch(setUser(response.data));
		}).catch(error => {
			console.log(error.response);
		})
	}
}

export function signup(creds) {
	return dispatch => {
		return axiosInstance.post('auth/signup', { firstName: creds.firstName, lastName: creds.lastName, email: creds.email, password: creds.password })
	}
}

export function forgotPassword(creds) {
	return axiosInstance.post('auth/forgot', { email: creds.email })
}

export function resetPassword(creds) {
	return dispatch => {
		return axiosInstance.post('auth/passwordreset/' + creds.token, { password: creds.password })
	}
}

export function login(creds) {
	return dispatch => {
		return axiosInstance.post('auth/login', { email: creds.email, password: creds.password })
			.then(response => {
        localStorage.setItem('token', response.data.token);
        axiosInstance.defaults.headers['Authorization'] = response.data.token;
        console.log(axiosInstance.defaults.headers);
				dispatch(setAuthenticated(true));
				dispatch(setUser(response.data));
			})
	}
}

export function logout() {
	return dispatch => {
		const user = {
			email: null,
			firstName: null,
			lastName: null,
			role: null,
			token: null
		}
		localStorage.removeItem('token');
		dispatch(setAuthenticated(false));
		dispatch(setUser(user));
		window.location.replace("/");
	}
}
