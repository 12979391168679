import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import PrivateRoute from './components/privateRoute.js'
import Dashboard from './pages/dashboard';
import Users from './pages/users';
import StorageLocations from './pages/storageLocations';
import Warranty from './pages/warranty';
import SalesTracker from './pages/salesTracker';
import Login from "./pages/login";
import Signup from "./pages/signup";
import NotFound from './pages/404';
import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/resetPassword';
import Location from './pages/location';
import Monitors from './pages/monitors';

class Routes extends Component  {

  render() {
    return(
      <Switch>
        <PrivateRoute 
          exact path="/" 
          component={Dashboard}
        />
        <PrivateRoute 
          exact path="/users" 
          component={Users}
        />
        <PrivateRoute 
          exact path="/storagelocations" 
          component={StorageLocations}
        />
        <PrivateRoute 
          path="/location" 
          component={Location}
        />
        <PrivateRoute 
          path="/warranty" 
          component={Warranty}
        />
        <Route 
          path="/monitors"
          component={Monitors}
        />
        <Route
          path="/salestracker"
          component={SalesTracker}
        />
        <Route 
          path="/login" 
          component={Login}
        />
        <Route 
          path="/signup"
          component={Signup}
        />
        <Route 
          path="/forgotpassword" 
          component={ForgotPassword}
        />
        <Route 
          path="/resetpassword/:token" 
          component={ResetPassword}
        />
        <Route path="*" component={NotFound} />
      </Switch> 
    )
  }

}

export default Routes;