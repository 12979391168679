import React from "react";
import styled from 'styled-components';
import { Search } from 'react-feather';
import Welcome from '../../../components/welcome'
import { withRouter } from 'react-router-dom';
import { getOrder, importOrder} from '../../../actions/orderActions';
import {connect} from 'react-redux';
import {notify} from 'reapop';
import { Input, Button } from 'reactstrap';
class Home extends React.Component {

	state = {
		submitted: false,
		importing: false,
		orderNumber: '',
		orderNumberError: false,
		orderNumberErrorMessage: null
	};
	
	handleChange = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
	}
	
	search = () => {
    const orderNumber = this.state.orderNumber;
		if (this.state.submitted || this.state.orderNumber.length <= 2) return;
		this.setState({ submitted: true });
    getOrder(orderNumber)
    .then(response => {
      this.props.history.push(`/warranty/order/${orderNumber}`);
    }).catch(error => {
      if (error.response.status === 404) {
        this.props.notify({ status: 'info', position: 'bl', title: `Importing Order #${orderNumber}`, message: `You will be redirected once the process is complete.` })
        importOrder(orderNumber)
        .then(response => {
          this.props.notify({ status: 'success', position: 'bl', title: `Imported Order #${orderNumber}`, message: `${response.data.itemImport.itemCount} items imported.` })
          return this.props.history.push(`/warranty/order/${orderNumber}`);
        }).catch(error => this.props.notify({ status: 'error', position: 'bl', title: `${error.response.status}`, message: `${error.response.statusText}` }), this.setState({ submitted: false, importing: false }));
      } else {
        this.props.notify({ status: 'error', position: 'bl', title: `${error.response.status}`, message: `Something went wrong. Try again.` });
        this.setState({ submitted: false });
      }
    })
  }


	render() {
		return (
			<Container>
				<Welcome backgroundImage="/warranty/welcome.jpg" title="Warranty QC/Shipping" />
				<Title><Search /> Quick Search</Title>
				<Cards>
					<Card>
						{ !this.state.importing ?
                <Input 
                  id="order-number"
                  name="orderNumber"
                  type="text"
                  bsSize="lg"
                  placeholder="Order Number"
                  value={this.state.orderNumber}
                  onChange={this.handleChange}
                  onKeyPress={event => { if (event.key === 'Enter') this.search() }}
                />
              :
							<div>Importing</div>
						}
					</Card>
					<Card>
						<Button color="primary" onClick={() => this.props.history.push(`/warranty/reports/shippingreport`)}>REPORTS</Button>
					</Card>
				</Cards>
			</Container>
		)
	}
}

const Container = styled.div`
	width: 100%;
	position: relative;
	background: #f4f7fc;
	padding: 40px;
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
`;

const Title = styled.div`
	font-size: 1.5em;
	margin: 40px 0;
`;

const Cards = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;

	.title {
		display: flex;
		align-items: center;
		font-weight: 300;
		font-size: 1.5em;
	}

	.description {
		font-weight: lighter;
	}
`;

const Card = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
	/* width: 500px; */
	height: 100px;
	margin-right: 10px;
	padding: 30px 40px;
	border-radius: 3px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;

	input {
		width: 300px;
	}
`;



export default withRouter(connect(null, {notify})(Home));