import React from "react";
import styled from 'styled-components';
import {connect} from 'react-redux';
import {notify} from 'reapop';
import { withRouter } from 'react-router-dom';
import { Input, Label, FormGroup } from 'reactstrap';
import { getLocations } from '../../../actions/storageLocationActions';
import { getItemsByOrderNumber, updateItemStatus, getOrderFromICN } from '../../../actions/itemActions';
import { sortBy, find, filter} from 'lodash';
import { importOrder } from '../../../actions/orderActions';
import Items from '../components/items';
import { Download, Package } from 'react-feather';
import { itemUpdated } from '../../../actions/socket';

class Scan extends React.Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.scanRef = React.createRef();
  
    // this.focusScanRef = this.focusScanRef.bind(this);
  }

	componentDidMount() {
    this.scanRef.current.focus();
    getLocations()
    .then(response => {
      this.setState({ locations: sortBy(filter(response.data, ['address.streetAddress', '533 Highway 36 North']), ['name']) });
    }).catch(error => this.props.notify({ status: 'error', position: 'bl', title: `${error.response.status}`, message: `${error.response.statusText}` }));
    
    itemUpdated((err, item) => this.updateItem(item));
    // this.focusScanRef();
    this.focusInput();
  }

  componentWillUnmount = () => {
    this.clearTimer();
  };

  clearTimer = () => {
    if (this.timerHandle) {
        clearTimeout(this.timerHandle);
        this.timerHandle = 0;
    }
  };

	state = {
    scan: '',
    shelf: '',
    locations: [],
    lastItem: null,
    items: [],
    error: false,
    errorMessage: '',
    success: false
  };
  
  focusInput = () => {
    this.scanRef.current.focus();

    this.timerHandle = setTimeout(() => {
      this.focusInput();
    }, 10000)
  }
  
  handleChange = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
  }

  background = () => {
    const { shelf, error, success } = this.state;
    if (error) return '#dc3545';
    if (success) return '#28a745'
    if (shelf !== '') {
      return '#007bff';
    } else return 'none';
  }

  error = (message) => {
    if (message) this.setState({ errorMessage: message });
    this.setState({ error: true });
    setTimeout(() => {
      this.setState({ error: false });
    }, 3000)
  }

  success = (item) => {
    console.log(item)
    this.setState({ success: true, lastItem: item});
    setTimeout(() => {
      this.setState({ success: false });
    }, 3000)
  }

  search = () => {
    const { scan, locations, shelf } = this.state;
    this.setState({ scan: '' , errorMessage: ''});
    if (scan.substring(0, 3) === 'ICN') {
      if(!shelf) return this.error();
      return this.ItemLookup(scan);
    }
    
    if (find(locations, ['_id', scan])) {
      this.setState({ shelf: scan, scan: ''});
    }
  }

  ItemLookup = (icn) => {
    updateItemStatus(icn, find(this.state.locations, ['_id', this.state.shelf]).name)
    .then(response => {
      this.success(response.data);
      if (!this.state.items || this.state.items[0] !== response.data.orderNumber) {
        getItemsByOrderNumber(response.data.orderNumber)
        .then(response => {
          this.setState({ items: response.data });
        })
      }
	  }).catch(error => this.getOrder(icn));
  }

  getOrder = (icn) => {
    getOrderFromICN(icn)
    .then(response => {
      this.importOrder(response.data.ordOrderNo, icn);
    }).catch(err => this.error('ICN is not valid.'))
  }

  importOrder = (orderNumber, icn) => {
    importOrder(orderNumber)
    .then(response => {
      this.ItemLookup(icn);
    }).catch(error => this.error('Error Importing Order.'))
  }

  updateItem = (newItem) => {
    this.setState({ items: this.state.items.map(item => (item._id === newItem._id) ? newItem : item) });
  }

	render() {
    const { scan, shelf, locations, items, lastItem, errorMessage } = this.state;
 		return (
			<Container>
        <Main background={this.background}>
          <Card>
            { shelf ? <div className="shelf">{find(locations, ['_id', shelf]).name}</div> : null }
            <div className="title">{ !shelf ? <React.Fragment><Download size="1em" />&nbsp;Scan a Shelf</React.Fragment> : <React.Fragment><Package size="1em" />&nbsp;Scan an Item</React.Fragment>}</div>
            <FormGroup>
              <Label className="label" for="scan">Shelf or Item ICN</Label>
              <input 
                className="scan-input"
                ref={this.scanRef} 
                autoComplete="off"
                id="scan"
                name="scan"
                placeholder="Shelf or Item ICN"
                value={scan}
                onChange={this.handleChange}
                onKeyPress={event => { if (event.key === 'Enter') this.search() }}
              />
            </FormGroup>
            <FormGroup>
              <Label className="label" for="shelf">Shelf</Label>
              <Input 
                type="select"
                name="shelf"
                id="shelf"
                bsSize="lg"
                value={shelf}
                onChange={this.handleChange}
              >
                <option value="">Select Location</option>
                {locations.map((location, i) => (
                  <option key={location._id} value={location._id}>{location.name}</option>
                ))}
              </Input>
            </FormGroup>
            <div className="error">{ errorMessage }</div>

            { lastItem ? 
               <LastItem>
                <div className="title">Last Item</div>
                Order Number: <a href={`/warranty/order/${lastItem.orderNumber}`}>{lastItem.orderNumber}</a><br/>
                {lastItem.icnID}<br/>
                {lastItem.itemDescription}<br/>
                {lastItem.qty}
               </LastItem> :
               null
            }
          </Card>
        </Main>
        <Items items={items} />
			</Container>
		)
	}
}

const Container = styled.div`
	width: 100%;
	position: relative;
	background: #f4f7fc;
	display: flex;
	flex: 1 0 auto;
  height: calc(100% - 56px);
  overflow: hidden;
`;

const Main = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  transition: 0.3s all ease-in-out; 
  background: ${props => props.background || 'none'};
  overflow-y: auto;
`;

const Card = styled.div`
	position: relative;
	background: white;
  width: 100%;
	padding: 30px 40px;
	border-radius: 3px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;

  .shelf {
    font-size: 6em;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-weight: bolder;
  }

  .title {
    font-size: 3em;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
  }

  .scan-input {
    height: calc(1.5em + 1rem + 2px);
    border: 1px solid #ced4da;
    color: #495057;
    background-color: #fff;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
    display: block;
    width: 100%;
  }

  .error {
    font-size: 2em;
    color: #dc3545;
  }
`;

const LastItem = styled.div`
  a {
    color: #007bff;
  }

  .title {
    font-size: 1.5em;
    font-weight: bold;
  }
`;

export default withRouter(connect(null, {notify})(Scan));