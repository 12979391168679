import React from "react";
import styled from 'styled-components';
import Clock from 'react-live-clock';

const Container = styled.div`
	width: 100%;
	min-height: 300px;
	max-height: 400px;
	position: relative;
	background: white;
	display: flex;
	flex: 1;
	border-radius: 3px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;

	.card {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-width: 400px;
		width: 50%;

		.welcome {
			font-size: 2em;
		}

		.time {
			font-weight: lighter;
			font-size: 1.3em;
      margin: 10px 0;
      text-align: center;
		}

		.notifications {
			.amount {
				display: inline;
				color: #dd3333;
			}
		}
	}

	.welcome-photo {
		overflow: hidden;
		position: relative;
		background-image: url('${props => props.backgroundImage || ''}');
		background-position: center;
		background-size: cover;
		flex: 1;
	}

	@media (max-width: 800px) {
		.card {
      min-width: 0px;
			width: 100%;
		}

		.welcome-photo {
			display: none;
		}
	}
`;

class Welcome extends React.Component {

  render() {
		return (
      <Container backgroundImage={this.props.backgroundImage}>
        <div className="card">
          <div className="welcome">{this.props.title}</div>
          <Clock className="time" format={'h:mma dddd MMMM Do'} ticking={true} />
					{this.props.notifications ?
						<div className="notifications"> 
						<div className="amount">{this.state.notifications}</div>&nbsp;
						new notifications
					</div> : 
					null
					}
        </div>
        <div className="welcome-photo" />
      </Container>
    )
  }
}

export default Welcome;