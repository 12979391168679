import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { login } from '../../actions/authenticationActions';
import { Button, FormGroup, FormFeedback, Form, Input } from 'reactstrap';

const Page = styled.div`
	display: flex;
	position: relative;
  padding: 40px 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-height: 100vh;
  background: url('https://source.unsplash.com/user/eberhardgross/1920x1080') no-repeat center center fixed;
  background-size: cover;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 50px;
  width: 100%;
  flex-shrink: 0;
  max-width: 500px;
  border-radius: 5px;
  background: #FAFAFA;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
  color: #2d3039;

  .sub-title {
    font-weight: lighter;
    font-size: 2em;
    margin: 20px 0;
    text-align: center;
  }

  .form {
    width: 100%;
  }

  .error {
    margin: 10px 0;
    margin-top: 15px;
    color: #f44336;
    font-weight: lighter;
  }

  .bottom {
    margin: 15px 0;
    width: 100%;
    text-align: center;

    .sub-text {
      font-weight: lighter;
      margin-top: 20px;
    }

    .link {
      color: #2196f3;
    }
  }
`;

class Login extends Component {

  componentDidMount() {
    if (this.props.isAuthenticated) this.props.history.push("/");
  }

  state = {
    submitted: false,
    error: null,
    emailError: false,
    emailErrorMessage: null,
    passwordError: false,
    passwordErrorMessage: null,
    email: '',
    password: ''
  };

  attemptLogin = (event) => {
    event.preventDefault();
    if (this.state.submitted) return;
    if (!this.state.email) return this.setState({ emailError: true, emailErrorMessage: 'Requires Email'});
    else this.setState({ emailError: false, emailErrorMessage: null});
    if (!this.state.password) return this.setState({ passwordError: true , passwordErrorMessage: 'Requires Password'});
    else this.setState({ passwordError: false, passwordErrorMessage: null})
    this.setState({ submitted: true });
    this.props.actions.login({email: this.state.email, password: this.state.password }).then(response => {
      if (this.props.history.location.state) this.props.history.push(this.props.history.location.state.from.pathname);
      else this.props.history.push("/");
    }).catch(error => {
      this.setState({error: 'Invalid Email and/or Password', submitted: false})
    })
  };

  handleChange = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
  }

  render() {
    return (
      <Page>
        <Container>
          <div className="sub-title">Sign In</div>
          <Form className="form" onSubmit={this.attemptLogin}>
            <FormGroup>
              <Input 
                id="email"
                name="email"
                type="email"
                bsSize="lg"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleChange}
                invalid={this.state.emailError}
              />
              <FormFeedback>{this.state.emailErrorMessage}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Input 
                id="password"
                name="password"
                type="password"
                bsSize="lg"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handleChange}
                invalid={this.state.passwordError}
              />
              <FormFeedback>{this.state.passwordErrorMessage}</FormFeedback>
            </FormGroup>
            <Button color="primary" size="lg" block>Login</Button>
          </Form>
          <div className="error">{this.state.error}</div>
          <div className="bottom">
            <div className="sub-text"><Link to="/forgotpassword" className="link">Forgot your password?</Link></div>
            <div className="sub-text">Don't have an account? <Link to="/signup" className="link">Sign up here.</Link></div>
          </div>
        </Container>
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ login }, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));