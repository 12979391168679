import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import configureStore from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import 'normalize.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'rc-switch/assets/index.css'
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Provider store={configureStore()}>
    <App />
  </Provider>,
 document.getElementById('root')
);

serviceWorker.unregister();
