import React, { Component } from "react";
import Logo from './logo';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from '../actions/authenticationActions';
import styled from 'styled-components';
import { withRouter, NavLink } from 'react-router-dom';
import { Menu } from 'react-feather';
import {
  Collapse,
  Navbar,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';


const Container = styled.div`
  .navbar {
    background: ${props => props.background || 'white'};
    color: ${props => props.color || '#2d3039'};
    min-height: 75px;

    .active {
      font-weight: bold;
    }

    .side-toggle {
      display: none;
    
      @media (max-width: 767px) {
        display: block;
      }
    }
  }
`;

const CustomMenu = styled.div`
  cursor: pointer;
`;

class NavContainer extends Component {

  state = {
    isOpen: false
  }

  toggle = () => {
    this.setState({isOpen: !this.state.isOpen});
  }

  openLink = (link) => {
    this.props.history.push("/" + link);
  }

  logout = () => {
    this.props.actions.logout();
  }

  render() {
    var { email, role, home, links } = this.props
    return (
      <Container color={this.props.color} background={this.props.background}>
        <Navbar className="navbar" expand="md">
          <NavLink to="/" ><Logo color={this.props.color} width="140px" /></NavLink>
          <CustomMenu><Menu className="side-toggle" onClick={this.toggle} /></CustomMenu>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink to={home || '/'} className="nav-link" exact activeClassName="active">Home</NavLink>
              </NavItem>
              { links ? 
                links.map((link, i) => (
                <NavItem key={i}>
                  <NavLink to={link.link} className="nav-link" exact activeClassName="active">{link.name}</NavLink>
                </NavItem>
                )) : null
              }
              { !home &&
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Departments
                  </DropdownToggle>
                  <DropdownMenu right>
                    <NavLink to="/" activeClassName="active"><DropdownItem>Home</DropdownItem></NavLink>
                    <NavLink to="/warranty" activeClassName="active"><DropdownItem>Warranty</DropdownItem></NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {email}
                </DropdownToggle>
                <DropdownMenu right>
                  { role <= 2 ?
                    <React.Fragment>
                      <NavLink to="/users" activeClassName="active"><DropdownItem>Users</DropdownItem></NavLink>
                      <NavLink to="/storagelocations" activeClassName="active"><DropdownItem>Storage Locations</DropdownItem></NavLink>
                      <DropdownItem divider />
                    </React.Fragment>
                    :
                    null
                  }
                  <DropdownItem onClick={this.logout}>Logout</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
	firstName: state.authReducer.firstName,
  lastName: state.authReducer.lastName,
  email: state.authReducer.email,
  role: state.authReducer.role
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ logout }, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavContainer));