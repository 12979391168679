import React from "react";
import dayjs from "dayjs"
import styled from 'styled-components';
import {connect} from 'react-redux';
import randomColor from "randomcolor";
import {notify} from 'reapop';
import { withRouter } from 'react-router-dom';
import { Info, Zap, List, Printer, Clock } from 'react-feather'
import { getOrder, addNote, updateOrder } from '../../../actions/orderActions';
import { getAttachments } from '../../../actions/attachmentActions';
import { Input, Label, FormGroup, Button, Table } from 'reactstrap';
import { Link } from "react-router-dom";
import EditOrder from '../components/editOrder';
import ItemHistory from '../components/itemHistory';
import AddItem from '../components/addItem';
import Notes from '../components/notes';
import Attachments from '../components/attachments';


class Order extends React.Component {

	componentDidMount() {
		getOrder(this.props.match.params.id).then(response => {
			this.setState({ order: response.data });
    }).catch(error => this.props.history.push('/warranty'));
    
    getAttachments(this.props.match.params.id).then(response => {
      this.setState({ attachments: response.data });
    }).catch(error => this.props.notify({ status: 'error', position: 'bl', title: `${error.response.status}`, message: `${error.response.statusText}` }))
	}

	state = {
    showEditOrder: false,
    showAddItem: false,
    showAttachments: false,
    showItemHistory: false,
    showNotes: false,
    orderNote: '',
    item: {},
    attachments: [],
		order: { items: [] , address: {}, numberOfPackages: 0, orderNotes: [] }
	};
	
	updateOrder = (event) => {
    var order = {...this.state.order};
    order[event.target.name] = event.target.value;
    this.setState({order});
  }

  update = (order) => {
    this.setState({ order: {...this.state.order, ...order} });
  }

  updateItem = (item) => {
    this.setState({ order: { ...this.state.order, items: [...this.state.order.items, item] }});
  }
  
  handleChange = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
  }
  
  addNote = () => {
    addNote(this.state.order._id, this.state.orderNote)
    .then(response => {
      this.setState({order: {...this.state.order, orderNotes: response.data.orderNotes}, orderNote: '' });
      this.props.notify({ status: 'success', position: 'bl', title: `Success`, message: `Note Added` });
    })
    .catch(error => this.props.notify({ status: 'error', position: 'bl', title: `${error.response.status}`, message: `${error.response.statusText}` }))
  }

  updatePackageNumber = () => {
    updateOrder(this.state.order._id, this.state.order)
    .then(response => {
      this.setState({order: {...this.state.order, numberOfPackages: response.data.numberOfPackages}});
      this.props.notify({ status: 'success', position: 'bl', title: `Success`, message: `Updated Packages to ${response.data.numberOfPackages}.` });
    })
    .catch(error => this.props.notify({ status: 'error', position: 'bl', title: `${error.response.status}`, message: `${error.response.statusText}` }))
  }

  toggle = () => {
    this.setState(prevState => ({showEditOrder: !prevState.showEditOrder }));
  }

  toggleAdd = () => {
    this.setState(prevState => ({showAddItem: !prevState.showAddItem }));
  }

  toggleItem = (item) => {
    this.setState(prevState => ({showItemHistory: !prevState.showItemHistory, item: item }));
  }

  toggleNote = () => {
    this.setState(prevState => ({showNotes: !prevState.showNotes }));
  }

  toggleAttachments = () => {
    this.setState(prevState => ({showAttachments: !prevState.showAttachments }));
  }

  randomColor = (status) => {
    if (status === "Released Work Order") return;
    var color = randomColor({
        luminosity: 'dark',
        seed: status
    });

    return {
      background: color,
      color: 'white'
    }
  }

	render() {
		const { id } = this.props.match.params;
		const { order, item, orderNote, showEditOrder, showItemHistory, showNotes, showAddItem, showAttachments, attachments } = this.state;
		return (
			<Container>
          <EditOrder toggle={this.toggle} show={showEditOrder} order={order} update={this.update}/>
          <AddItem toggle={this.toggleAdd} show={showAddItem} orderNumber={order.orderNumber} update={this.updateItem}/>
          <ItemHistory toggle={this.toggleItem} show={showItemHistory} item={item} update={this.update}/>
          <Notes toggle={this.toggleNote} show={showNotes} notes={order.orderNotes} />
          <Attachments toggle={this.toggleAttachments} show={showAttachments} attachments={attachments} />
				  <TopTitle><div className="light">Order</div>&nbsp;{id}</TopTitle>
          <Head>
            <Title><Info size="1em"/>&nbsp;Info</Title>
            <div className="grow"/>
            <Button color="primary" onClick={this.toggleAttachments}>View Attachments</Button>
            <div className="split" />
            <Button color="primary" onClick={this.toggle}>Edit Order Info</Button>
            <div className="split" />
            <Link to="/warranty/scan"><Button color="primary">Scan Items</Button></Link>
          </Head>
					<Card>
						<div className="column">
							<div className="info"><div className="info-title">Order Status:</div>&nbsp;{order.orderStatus}</div>
							<div className="info"><div className="info-title">Sales Man:</div>&nbsp;{order.salesMan}</div>
							<div className="info"><div className="info-title">Created Date:</div>&nbsp;{dayjs(order.createdDate).format("MMM D, YYYY")}</div>
              <div className="info"><div className="info-title">Super Int:</div>&nbsp;{order.super}</div>
							<div className="info"><div className="info-title">Super Phone:</div>&nbsp;<a href={`tel:${order.superPhone}`}>{order.superPhone}</a></div>
              <div className="info"><div className="info-title">Drop Off Location:</div>&nbsp;{order.dropOffLocation}</div>
						</div>
            {
              order.orderNotes && order.orderNotes.length ?
              <div className="column">
                <div className="title">Last Note</div>
                <div className="note">
                  {order.orderNotes[order.orderNotes.length - 1].note}
                </div>
                <div className="note-info">
                  {order.orderNotes[order.orderNotes.length - 1].user}
                  <div className="grow" />
                  {dayjs(order.orderNotes[order.orderNotes.length - 1].noteCreated).format("MMM D, h:mm a")}
                </div>
                <Button color="secondary" onClick={this.toggleNote}>View All</Button>
              </div> :
              null
            }
            <div className="column address">
              <div className="title">Address</div>
              <div className="address-line">{order.address.streetAddress}</div>  
              <div className="address-line">{order.address.city} {order.address.state}</div>
              <div className="address-line">{order.address.zip}</div>
              <div className="address-line">{order.address.subdivision}</div>
            </div>
					</Card> 
          <Title><Zap size="1em"/>&nbsp;Actions</Title>
					<Card>
            <div className="column">
              <FormGroup>
                <Label className="label" for="numberofpackages">Number of Packages</Label>
                <Input 
                  id="numberofpackages"
                  bsSize="lg"
                  name="numberOfPackages"
                  placeholder="Number of Packages"
                  type="number"
                  value={order.numberOfPackages}
                  onChange={this.updateOrder}
                />
              </FormGroup>
              <div>
                <Button disabled={isNaN(order.numberOfPackages) || order.numberOfPackages.length < 1 } color="primary" onClick={this.updatePackageNumber}>Update</Button>
              </div>
            </div>
            <div className="column">
              <FormGroup>
                <Label for="note">Add Note</Label>
                <Input 
                  id="note"
                  bsSize="lg"
                  type="textarea" 
                  name="orderNote"
                  value={orderNote}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <div>
                <Button disabled={orderNote.length < 1 } color="primary" onClick={this.addNote}>Add Note</Button>
              </div>
            </div>
            <div className="column printing">
              <Button className="printing" tag="a" rel="noopener noreferrer" href={`https://api.benecab.io/v1/printablereports/warranty/shippinglabels/${id}`} target="_blank" color="primary">Print Item Labels</Button>
              <Button tag="a" rel="noopener noreferrer" href={`https://api.benecab.io/v1/printablereports/warranty/packinglabels/${id}`} target="_blank" color="primary">Print Packing Labels</Button>
              <Button className="add-item" color="warning" onClick={this.toggleAdd} >Add Item</Button>
            </div>
					</Card>
					<Title><List size="1em"/>&nbsp;Items For Order #{id}</Title>
          <CustomTable striped>
            <thead>
              <tr>
                <th>icnID</th>
                <th>Status</th>
                <th>Box Number</th>
                <th>Qty</th>
                <th>Description</th>
                <th>History</th>
                <th>Print</th>
              </tr>
            </thead>
            <tbody>
              {order.items.map((item, i) => (
              <tr key={item._id}>
                <td>{item.icnID}</td>
                <td style={this.randomColor(item.currentStatus)}>{item.currentStatus}</td>
                <td>{item.boxNumber}</td>
                <td>{item.qty}</td>
                <td>{item.itemDescription}</td>
                <td><Clock className="history" onClick={() => this.toggleItem(item)} /></td>
                <td><a href={`https://api.benecab.io/v1/printablereports/warranty/shippinglabels/${id}?item=${item._id}`} rel="noopener noreferrer" target="_blank"><Printer /></a></td>
              </tr>
              ))}
            </tbody>
          </CustomTable>
			</Container>
		)
	}
}

const Container = styled.div`
	width: 100%;
	position: relative;
	background: #f4f7fc;
	padding: 40px;
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
`;

const TopTitle = styled.div`
	font-size: 2em;
	display: flex;
  align-items: center;
  
  .light {
    font-weight: lighter;
    color: #bbbbbb;
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;

  .split {
    width: 10px;
  }

  .grow {
    flex: 1;
  }
`;


const Title = styled.div`
	font-size: 1.5em;
	margin: 20px 0;
	display: flex;
	align-items: center;
`;

const Card = styled.div`
  display: flex;
	position: relative;
	justify-content: center;
	align-items: top;
	background: white;
	padding: 30px 40px;
	border-radius: 3px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;

  @media (max-width: 950px) {
    flex-wrap: wrap;

    .column {
      width: 100%;
      margin: 10px 0;
      flex: unset !important;
    }

    .address {
      align-items: flex-start !important;
    }

  }

  .address {
    align-items: flex-end;
  }

  .add-item {
    margin-top: 10px;
  }

  .printing {
    margin-bottom: 10px;
  }

	.column {
    flex: 1;
		display: flex;
		flex-direction: column;
    padding: 0 20px;
    
    .title {
      font-size: 1.4em;
      font-weight: lighter;
      margin-bottom: 10px;
    }

    .note {
      min-height: 50px;
    }

    .note-info {
      color: #bbbbbb;
      display: flex;
      font-size: 0.8em;
      margin-bottom: 20px;

      .grow {
        flex: 1;
      }
    }

		.info {
			margin-bottom: 5px;
      display: flex;
      align-items: center;
      font-weight: bold;


			.info-title {
        font-weight: lighter;
			}

      
		}
	}
`;

const CustomTable = styled(Table)`
  .history {
    cursor: pointer;
  }

  th, td {
    vertical-align: middle;
  }
`;

export default withRouter(connect(null, {notify})(Order));