import React from 'react';
import Styled from 'styled-components';
import moment from 'moment';

const Header = ({ title, error, loading, updated }) => (
    <TopBar>
        <Title>{ title }</Title>
        <Updated>
            { loading && 'Loading...' }
            { error && 'ERROR FETCHING DATA' }
            { !loading && !error ? moment(updated).calendar() : null }
        </Updated>
    </TopBar>
)

export default Header;

const TopBar = Styled.div`
    background-color: black;
    height: 100px;
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Title = Styled.h1`
    font-size: 2em;
    text-align: center;
    font-weight: lighter;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0;
    color: #fff;
`;

const Updated = Styled.p`
    margin: 0;
    color: #fff;
`;