import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const SampleBlocks = ({ samples }) => (
    <SampleContainer>
        { samples.map((o, i) => (
            <SampleBlock key={i} className={!o.checkedIn && 'checked-out'}>
                <h2 className="bin">BIN: { o.binID }</h2>
                <h2 className="finish">{ o.finish }</h2>
                <h2 className="facemat">{ o.facemat }</h2>
                <hr />
                <p>{ o.checkedIn ? (`Checked In: ${moment(o.checkedInTime).format('MM-DD-YY h:mma')}`) : (`Checked Out: ${moment(o.checkedOutTime).format('MM-DD-YY h:mma')}`)}</p>
            </SampleBlock>
        ))}
    </SampleContainer>
)

const SampleContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const SampleBlock = styled.div`
    width: calc(33.33% - 10px);
    text-align: center;
    background-color: #50CB49;
    margin-bottom: 20px;
    padding: 20px 20px;

    @media (max-width: 1200px) {
        width: calc(50% - 10px);
    }

    @media (max-width: 550px) {
        width: 100%;
    }

    &.checked-out {
        background-color: #CB5549;
    }

    .bin {
        font-weight: lighter;
        font-size: 2em;
    }
    .finish {
        font-weight: bolder;
        font-size: 1em;
    }
    .facemat {
        font-weight: bolder;
        font-size: 1.5em;
    }
    p {
        text-transform: uppercase;
        font-size: 1em;
    }
`;

export default SampleBlocks;