import React from "react";
import styled from 'styled-components';
import { List, Printer } from 'react-feather';
import { findIndex, sortBy } from 'lodash'
import { Table, Button } from 'reactstrap';
import { getLocations } from '../../../actions/storageLocationActions';
import NewLocation from '../components/newLocation';
import EditLocation from '../components/editLocation';

const Container = styled.div`
	width: 100%;
	position: relative;
	background: #f4f7fc;
	color: #2d3039;
	padding: 40px;
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
`;

const Head = styled.div`
  display: flex;
  align-items: center;

  .grow {
    flex: 1;
  }
`;

const Title = styled.div`
	font-size: 2em;
	margin: 40px 0;
	display: flex;
	align-items: center;
`;

const CustomTable = styled(Table)`
  th, td {
    vertical-align: middle;
  }

  .disabled {
    background: #dd3333 !important;
    color: white !important;

    .cell-custom, .cell-custom-title {
      color: white;

      .name {
        color: white;
      }
    }
    
  }
`;


class StorageLocations extends React.Component {

	state = {
    showNewLocation: false,
    showEditLocation: false,
    editLocation: null,
		locations: []
	}


	componentDidMount() {
    getLocations().then(response => {
      this.setState({ locations: sortBy(response.data, ['name']) });
	  }).catch(error => console.log(error.response))
  }

  toggleNewLocation = () => {
    this.setState(prevState => ({showNewLocation: !prevState.showNewLocation }));
  }

  toggleEditLocation = (location) => {
    this.setState(prevState => ({showEditLocation: !prevState.showEditLocation, editLocation: location }));
  }

  addLocation = (location) => {
    this.setState({locations: [...this.state.locations, location]})
  }

  updateLocation = (newLocation) => {
    this.setState({ locations: this.state.locations.map(location => (location._id === newLocation._id) ? newLocation : location) });
  }

  deleteLocation = (id) => {
    const index = findIndex(this.state.locations, ['_id', id]);
    this.setState({
      locations: this.state.locations.filter((_, i) => i !== index)
    });
  }


	render() {
    const { locations, showNewLocation, showEditLocation, editLocation } = this.state;
		return (
			<Container>
        <NewLocation toggle={this.toggleNewLocation} show={showNewLocation} update={this.addLocation}/>
        <EditLocation toggle={this.toggleEditLocation} show={showEditLocation} storageLocation={editLocation} delete={this.deleteLocation} update={this.updateLocation}/>
        <Head>
          <Title><List size="1em" />&nbsp;&nbsp;Storage Locations</Title>
          <div className="grow"/>
          <Button color="primary" onClick={this.toggleNewLocation}>New Location</Button>
        </Head>
        <CustomTable striped>
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Edit</th>
              <th>Print Label</th>
            </tr>
          </thead>
          <tbody>
            {locations.map((location, i) => (
            <tr key={location._id} >
              <td>{location.name}</td>
              <td>{location.address.streetAddress}</td>
              <td><Button color="primary" onClick={() => this.toggleEditLocation(location)}>Edit</Button></td>
              <td><a href={`https://api.benecab.io/v1/printablereports/warranty/shelflabels/${location._id}`} rel="noopener noreferrer" target="_blank"><Printer /></a></td>
            </tr>
            ))}
          </tbody>
        </CustomTable>

			</Container>
		)
	}
}

export default StorageLocations