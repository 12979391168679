import React from "react";
import styled from 'styled-components';
import { ListGroup, ListGroupItem, ListGroupItemText } from 'reactstrap';

class Items extends React.Component {

	render() {
		return (
      <Sidebar>
        <ListGroup>
          {this.props.items.map((item, i) => (
            <CustomListGroupItem key={item._id} active={item.onShelf}>
              <ListGroupItemText>Box: {item.boxNumber}</ListGroupItemText>
              <ListGroupItemText>Qty: {item.qty}</ListGroupItemText>
              <ListGroupItemText>Order: {item.orderNumber}</ListGroupItemText>
              <ListGroupItemText>Description: {item.itemDescription}</ListGroupItemText>
              <ListGroupItemText>Status: {item.currentStatus}</ListGroupItemText>
            </CustomListGroupItem>
          ))}
        </ListGroup>
      </Sidebar>
		)
	}
}

const Sidebar = styled.div`
  width: 400px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
  overflow-y: auto;
`;

const CustomListGroupItem = styled(ListGroupItem)`
  border-radius: 0px !important;
  border-left: none;
  border-right: none;
  transition: 0.3s all ease-in-out;
`;

export default Items;