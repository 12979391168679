import { axiosInstance } from './axios';

const url = 'storagelocations';

export function getLocations() {
	return axiosInstance.get(url)
}

export function newLocation(storageLocation) {
	return axiosInstance.post(url, storageLocation)
}

export function deleteLocation(id) {
	return axiosInstance.delete(`${url}/${id}`)
}

export function editLocation(id, storageLocation) {
	return axiosInstance.put(`${url}/${id}`, storageLocation)
}