import React from 'react';
import Styled from 'styled-components';
import DatePicker from "react-datepicker";
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";

const DateSelect = ({ selectedDate, onChange }) => (
    <Holder>
        <h4>Report Date</h4>
        <DatePicker selected={selectedDate} onChange={onChange} />
        <p>{moment(selectedDate).format('dddd MMM Do YYYY')} Selected</p>
    </Holder>
);

const Holder = Styled.div`
    h4 {
        font-weight: bolder;
        text-transform: uppercase;
        color: #585858;
        font-size: .75em;
    }
    p {
        margin-top: 10px;
        font-weight: lighter;
        font-size: 1em;
    }
`;

export default DateSelect;